
import {
    Box,
    IconButton,
    Typography,
    Button,
  } from "@mui/material";
  import Modal from "react-modal";
  import CloseIcon from "@mui/icons-material/Close";
  import { styled } from '@mui/material/styles';

  

    const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "700px",
      maxHeight:'90%',
      transform: "translate(-50%, -50%)",
    },
  };
  


  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const ComplainModal = ({ ModalIsOpen, closeModal,queryData}) => {

    return (
      <Box sx={{ width: "800px" }}>
        <Modal
          isOpen={ModalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <div className="w-100 text-start">
                 <p className="h3 my-4 text-start"  >Complain Details</p> 
              </div>
             
              <IconButton onClick={closeModal} aria-label="delete">
                <CloseIcon  />
              </IconButton>
            </Box>
            <hr className="m-0"/>
         







            <div className="mt-4">
            <div className="row">
            <div className="col row justify-content-center">
                <div className="col-6 ">
                      <p className="fw-bold" >Name</p></div>
                </div>
              
            <div className="col">
                <p className="inter-300 text-muted"  style={{ fontFamily: `GoogleSans, sans-serif`,textTransform:'capitalize'}} >
                {queryData?.name}</p>
                </div>
         </div>

   <div className="row">
           <div className="col row justify-content-center">
                <div className="col-6 ">
                <p className="fw-bold" >Email</p>
                </div>
                </div>
            <div className="col"><p className="inter-300 text-muted"  style={{ fontFamily: `GoogleSans, sans-serif`}}>
                {queryData?.email}</p>
                </div>
         </div>
         <div className="row">
           <div className="col row justify-content-center">
                <div className="col-6 ">
                <p className="fw-bold" >Phone No.</p>
                </div>
                </div>
            <div className="col"><p className="inter-300 text-muted"  style={{ fontFamily: `GoogleSans, sans-serif`}}>
                {queryData?.phoneNumber}</p>
                </div>
         </div>

         <div className="row">
           <div className="col row justify-content-center">
                <div className="col-6 ">
                <p className="fw-bold" >City</p>
                </div>
                </div>
            <div className="col"><p className="inter-300 text-muted"  style={{ fontFamily: `GoogleSans, sans-serif`}}>
                {queryData?.city}</p>
                </div>
         </div>




       
         <div className="row">
           <div className="col row justify-content-center">
                <div className="col-6 ">
                <p className="fw-bold" >Message</p>
                </div>
                </div>
            <div className="col">
              <p className="inter-300 text-muted"  style={{ fontFamily: `GoogleSans, sans-serif`}}>
                
                {/* {queryData?.message?.split(' ').slice(0, 8).join(' ')}
            {queryData?.message?.split(' ').length > 8 ? '...' : ''} */}
            {queryData?.message}
                </p></div>
         </div>


    



            </div>
            



          </Box>
        </Modal>
      </Box>
    );
  };
  
  export default ComplainModal;
  