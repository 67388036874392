
import React, {useRef, useState, useEffect } from 'react';
import './xlsx.css';
import {
  RangeDirective,
  RangesDirective,
  SheetDirective,
  SheetsDirective,
  SpreadsheetComponent,
  ColumnsDirective,
  ColumnDirective
} from '@syncfusion/ej2-react-spreadsheet';

import * as XLSX from 'xlsx';
import { baseURL } from '../../axios/axios';
import {
  Box,
  IconButton,

} from "@mui/material";

import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
  
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "80%",
    height:"80%",

    transform: "translate(-50%, -50%)",
  },
};


const XlsxEditor = ({fileData,folderId,closeEditor, EditorIsOpen,fileName}) => {

  const SSObj = useRef(null);



  const onCreate = () => {
    // Use fetch to get data from the live URL
    fetch(`${fileData?.doc}`)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => {
        const binaryString = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
        const workbook = XLSX.read(binaryString, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        const sheetModel = [
          {
            index: 0,
            name: 'Order Details',
            ranges: [{ dataSource: data }],
            columns: [
              { width: 150 },
              { width: 110 },
              { width: 110 },
              { width: 85 },
              { width: 85 },
              { width: 85 }
            ]
          }
        ];
        SSObj.current.insertSheet(sheetModel);
      })
      .catch(error => {
        console.error('Error fetching live data:', error);
      });
  };

  useEffect(() => {
    onCreate(); // Load data when the component mounts
  }, []); // Empty dependency array means this effect runs only once on mount 

// Inside loadExcelFile function


  return (
    <div>
  
     

<Box>
        <Modal
          isOpen={EditorIsOpen}
          onRequestClose={closeEditor}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Box sx={{ padding: "20px",height:'100%' }}>
            <Box
            
              sx={{
            
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                // height:'100%'
              }}
            >
              <IconButton onClick={()=>{
                closeEditor()
              }} aria-label="delete">
                <CloseIcon sx={{ color: "#000" }} />
              </IconButton>
            </Box>
      {/* <p>File URL: {fileUrl}</p> */}
            <Box sx={{height:'100%'}}>
    <div className=" w-100 h-100">


      <SpreadsheetComponent
        ref={(s) => (SSObj.current = s)}
        created={onCreate}
              allowSave={true}
              saveUrl="https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/save"
      >
      
      </SpreadsheetComponent>

   </div>
     
            </Box>
          </Box>
        </Modal>
      </Box>

   
    </div>
  );
};

export default XlsxEditor;
