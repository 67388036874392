import { Box, IconButton, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import {
  CreateNewFolder,
  GetAllFolder,
  unSubscribe,
} from "../../../redux/slice/DocumentsCrudSlice";
import { GetAllFiles } from "../../../redux/slice/DocumentsCrudSlice";
import { useTheme } from "../../../ThemeProvider";
import DashButton from "../../Buttons/DashButton";
import { baseURL } from "../../../axios/axios";
import toast, { Toaster } from "react-hot-toast";
import { displayName } from "react-quill";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "700px",
    transform: "translate(-50%, -50%)",
  },
};

const PopupToBlockchain = ({
  ModalIsOpen,
  closeModal,
  organizationId,
  folderId,
  item,
  getAllDocuments,
}) => {
  const { theme } = useTheme();
  const { isLoading } = useSelector((state) => state.document);
  const updatedData = useSelector((state) => state.user.userData.userDetail);

  const [showHash, setShowHash] = useState(false);
  const [hash, setHash] = useState("");
  const [validationMessages, setValidationMessages] = useState({
    name: "",
    description: "",
  });
  const [data, setdata] = useState({
    id: "",
    data: {
      name: "",
      description: "",
      parentFolderId: "64bea28f28a2be0a7d3b02c7",
    },
  });

  const dispatch = useDispatch();

  //extract organization id from token
  let user = JSON.parse(localStorage.getItem("access-token"));

  const token = user;
  const decodedToken = jwtDecode(token);
  const userId = decodedToken.id;

  //set organization to payload
  useEffect(() => {
    setdata((prevData) => ({ ...prevData, id: userId }));
  }, []);

  //get updated value of name of folder
  const handleNameChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        name: e.target.value,
      },
    }));
    setValidationMessages((prevMessages) => ({
      ...prevMessages,
      name: e.target.value.trim() ? "" : "Folder Name is required",
    }));
  };

  //get updated value of descrption of folder
  const handleDescriptionChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        description: e.target.value,
      },
    }));
    setValidationMessages((prevMessages) => ({
      ...prevMessages,
      description: e.target.value.trim() ? "" : "Description is required",
    }));
  };

  // const onSubmit = async () => {
  //   try {
  //     const token = JSON.parse(localStorage.getItem("access-token"));

  //     if (!token) {
  //       throw new Error("Authentication token not found. Please log in.");
  //     }

  //     const response = await fetch(`${baseURL}api/stripe/cancel-subscription`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const result = await response.json();

  //     toast.success("Organization has been unsubscribed successfully.");
  //     window.location.reload();
  //     return result;
  //   } catch (error) {
  //     console.error("Error cancelling subscription:", error);
  //     throw error;
  //   }
  // };

  const handleCommitToBlockchain = async () => {
    toast.success(
      "Please wait! your media file has been committing to Blockchain"
    );
    const data = {
      userId: updatedData.data.id,
      userName: updatedData.data.name,
      docHash: item.documentId,
      docName: item.name,
      organizationName: updatedData.data.name,
    };
    try {
      const response = await fetch(
        "https://api.anshinbase.com/api/ledger/submit/org1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        const obj = {
          docHash: responseData.transaction.details.docHash,
        };
        const response2 = await fetch(
          `${baseURL}api/workDocs/updateDocumentHash/${item.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(obj),
          }
        );
        if (response2.ok) {
          setShowHash(true);

          setHash(obj.docHash);
        }
      } else {
        console.error("Error submitting data:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(hash).then(
      () => {
        toast.success("Copied!");
      },
      () => {
        toast.error("Failed to copy!");
      }
    );
  };

  const handleClose = () => {
    setShowHash(false);
    closeModal();
    getAllDocuments();
  };
  return (
    <Box>
      <Toaster />
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {showHash ? (
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "0px",
              }}
            >
              <h3
                className="text-center bdms-title"
                style={{
                  color: `${theme === "light" ? "#000" : "white"}`,
                  textAlign: "center",
                  marginLeft: "125px",
                }}
              >
                Committed to Blockchain
              </h3>
              <IconButton
                onClick={() => {
                  closeModal();
                  getAllDocuments();
                  setShowHash(false);
                  setdata((prevData) => ({
                    ...prevData,
                    data: {
                      ...prevData.data,
                      description: "",
                      name: "",
                    },
                  }));
                  setValidationMessages((prevMessages) => ({
                    ...prevMessages,
                    name: "",
                    description: "",
                  }));
                }}
                aria-label="delete"
              >
                <CloseIcon
                  sx={{ color: `${theme === "light" ? "#000" : "white"}` }}
                />
              </IconButton>
            </Box>

            <Box>
              <div class="form-group row my-3">
                <label
                  for="inputPassword"
                  class="col-sm-12  "
                  style={{
                    color: `${theme === "light" ? "" : "white"}`,
                    textAlign: "center",
                  }}
                >
                  {/* Note: */}
                  <br />
                  {"     "}
                  <span
                    style={{
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    Please copy to clipboard this Hash because this popup will
                    not appear again
                  </span>
                  <br />
                  <span
                    style={{
                      // fontSize: "20px",
                      marginTop: "60px",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {hash}
                  </span>
                </label>
                {/* <div class="col-sm-6">
                <input
                  type="text"
                  class="bdms-profile-input"
                  id="inputPassword"
                  placeholder="Name"
                  value={data.data.name}
                  onChange={handleNameChange}
                />
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationMessages.name}
                </div>
              </div> */}
              </div>

              <Box
                variant="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DashButton
                  onClick={handleClose}
                  color="dark"
                  label={"No"}
                  disabled={isLoading}
                />
                <span
                  style={{
                    margin: "5px",
                  }}
                ></span>

                <DashButton
                  onClick={handleCopy}
                  color="dark"
                  label={"Copy to Clipboard"}
                  disabled={isLoading}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "30px",
              }}
            >
              <h3
                className="text-center bdms-title"
                style={{
                  color: `${theme === "light" ? "#000" : "white"}`,
                  textAlign: "center",
                  marginLeft: "150px",
                }}
              >
                Commit to Blockchain
              </h3>
              <IconButton
                onClick={() => {
                  closeModal();
                  setdata((prevData) => ({
                    ...prevData,
                    data: {
                      ...prevData.data,
                      description: "",
                      name: "",
                    },
                  }));
                  setValidationMessages((prevMessages) => ({
                    ...prevMessages,
                    name: "",
                    description: "",
                  }));
                }}
                aria-label="delete"
              >
                <CloseIcon
                  sx={{ color: `${theme === "light" ? "#000" : "white"}` }}
                />
              </IconButton>
            </Box>

            <Box>
              <div class="form-group row my-3">
                <label
                  for="inputPassword"
                  class="col-sm-12  "
                  style={{
                    color: `${theme === "light" ? "" : "white"}`,
                    textAlign: "center",
                  }}
                >
                  Are you sure you want to proceed with committing this file to
                  the Blockchain?
                </label>
                {/* <div class="col-sm-6">
              <input
                type="text"
                class="bdms-profile-input"
                id="inputPassword"
                placeholder="Name"
                value={data.data.name}
                onChange={handleNameChange}
              />
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationMessages.name}
              </div>
            </div> */}
              </div>

              <Box
                variant="div"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DashButton
                  onClick={closeModal}
                  color="dark"
                  label={"No"}
                  disabled={isLoading}
                />
                <span
                  style={{
                    margin: "5px",
                  }}
                ></span>

                <DashButton
                  onClick={handleCommitToBlockchain}
                  color="dark"
                  label={"Yes"}
                  disabled={isLoading}
                />
              </Box>
            </Box>
          </Box>
        )}
        {/* <Box sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "40px",
            }}
          >
            <h3
              className="text-center bdms-title"
              style={{
                color: `${theme === "light" ? "#000" : "white"}`,
                textAlign: "center",
              }}
            >
              Commit to Blockchain
            </h3>
            <IconButton
              onClick={() => {
                closeModal();
                setdata((prevData) => ({
                  ...prevData,
                  data: {
                    ...prevData.data,
                    description: "",
                    name: "",
                  },
                }));
                setValidationMessages((prevMessages) => ({
                  ...prevMessages,
                  name: "",
                  description: "",
                }));
              }}
              aria-label="delete"
            >
              <CloseIcon
                sx={{ color: `${theme === "light" ? "#000" : "white"}` }}
              />
            </IconButton>
          </Box>

          <Box>
            <div class="form-group row my-3">
              <label
                for="inputPassword"
                class="col-sm-12  "
                style={{ color: `${theme === "light" ? "" : "white"}` }}
              >
                Are you sure you want to proceed with committing this file to
                the Blockchain?
              </label>
              {/* <div class="col-sm-6">
                <input
                  type="text"
                  class="bdms-profile-input"
                  id="inputPassword"
                  placeholder="Name"
                  value={data.data.name}
                  onChange={handleNameChange}
                />
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationMessages.name}
                </div>
              </div> 
            </div>

            <Box variant="div">
              <DashButton
                onClick={closeModal}
                color="dark"
                label={"No"}
                disabled={isLoading}
              />
              <span
                style={{
                  margin: "5px",
                }}
              ></span>

              <DashButton
                onClick={onSubmit}
                color="dark"
                label={"Yes"}
                disabled={isLoading}
              />
            </Box>
          </Box>
        </Box> */}
      </Modal>
    </Box>
  );
};

export default PopupToBlockchain;
