import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import '../../Layouts/NavBar.css';
import logo from '../../Images/Logo.png';
import main from '../../Images/aboutus-mainimage.png';
import about1 from '../../Images/about1.png';
import aboutGradiant  from'../../Images/aboutGradiant.png';
// import about2 from '../../Images/about2.png';
import mission from '../../Images/mission.png';
import vision from '../../Images/vision.png';
import EmailIcon from '@mui/icons-material/Email';
import { p, Button, Box } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails, } from '@mui/material'
import ForthPortion from '../LandingPage.jsx/ForthPortion';
import { useNavigate } from 'react-router-dom';
import { Navbarvalue } from '../../HeaderValue';


const About = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [expanded, setExpanded] = useState(false)
    const handleChange = (isExpanded, panel) => {
        setExpanded(isExpanded ? panel : false)
    }

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const handleItemClick = (index) => {
        setActiveItem(index);
    };

    const {updateValue}= Navbarvalue()

    const navigate = useNavigate()
    return (
        <>
         <div
        className="col text-center background-image"
        style={{
       
          backgroundImage: `url('${aboutGradiant}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "500px",
          display:"flex",
          alignItems:'center'
     
        }}
      >
        <div className="container " >
           <div className='row mt-md-0 mt-4' >
                <div className='col-md-7 text-start'>
                   
                    <p className='googleSans-bold witoutgradian ' style={{ color: '#19154E',  display: 'inline' }}>
                        We have faith in{' '}  <span variant='' className='googleSans-bold  gradiantext' style={{ color: '#F90401',  display: 'inline' }}>
                        UNLOCKING
                    </span>   <span  className='googleSans-bold  gradiantext' style={{ color: '#F90401' }}> SIMPLICITY.</span>
                    </p>
                  
               
             
                <p  className='googleSans-medium h5' style={{ color: '#19154E'}}>or us, you, and your customers</p>
                <button style={{
                    backgroundColor: '#19154E',
                    marginTop: 3,
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    padding:'10px 30px',
                    
                   
                    
                }}
                className=' btn btn-lg googleSans-medium rounded-1' onClick={()=>
                  {  navigate('/contact')
                updateValue(3)
                }
                }
                >Contact Zetta Circles</button>  
                </div>
                <div className='col-md-5 d-flex justify-content-center mt-md-0 mt-sm-4 mt-4 '>
                    <img src={main} alt="" className='img-fluid' style={{width:'500px'}}/>
                </div>
              
            </div>
        </div>
      </div>
          
           <div className="container mt-5">
             <div className='row  '  style={{overflow:"hidden"}}>
                <div className="col-12 col-sm-12 col-md-6 mb-5">
                    <div className='img-conatiner d-flex align-items-center h-100'>
                        <img src={about1} alt="img" className='img-fluid'  />
                       
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 ps-3">
                    <p  className='googleSans-medium h5' style={{ color: '#F90401', fontWeight: 'bold' }} >ABOUT US</p>
                    <p  className='googleSans-bold' style={{ color: '#19154E', marginTop: '15px',fontSize:'50px', lineHeight:'52.8px',letterSpacing:'-1px' }} >Welcome to Zetta Circles</p>
                    <p  className='googleSans-normal ' 
                    style={{ fontSize:'16px', lineHeight:'31.6px', letter:'0.2px'}}
                     > Lorem ipsum dolor sit amet consectetur, adipisicing  
                     elit.Corporis autem, dolorum consequatur incidunt et 
                      sed ipsa deleniti modi eos quas, tenetur eaque itaque amet.
                       Eius eaque atque explicabo est.</p>
                    <p className='googleSans-normal '  
                     style={{ fontSize:'16px', lineHeight:'31.6px', letter:'0.2px'}} 
                    >
                         Corporis autem, dolorum consequatur incidunt et 
                          expedita sed ipsa deleniti modi eos quas, tenetur
                           eaque itaque amet. Eius eaque atque explicabo est.</p>
                </div>
            </div>
           </div>
           
           



            <div className='row justify-content-center mt-5' style={{ backgroundColor: '#F5F9FD', width:"100%" }}>
                <div className="col-md-10">
                                <p  className='googleSans-bold h1' style={{ textAlign: 'center', color: '#19154E', marginTop: '30px', fontWeight: 'bold' }}>Who Are We?</p>
                <div className='container'>
                    <div className='row' style={{padding:"20px"}}>
                    <div className=" col-sm-12 col-md-6  mt-5">
                    <img src={mission} alt="mission img img-fluid" style={{width:'50px', height:'50px'}}  />
                    <p className='googleSans-bold h4' style={{ color: '#19154E', marginTop: '15px', fontWeight: 'bold' }}>Mission</p>
                    <p className='googleSans-normal '   style={{ fontSize:'16px', lineHeight:'31.6px', letter:'0.2px'}} >Lorem ipsum dolor, sit amet 
                    consectetur adipisicing elit. Quibusdam voluptatibus sed atque recusandae beatae 
                    dolorem debitis veniam in eos dolorum placeat magni unde, vitae et, sequi veritatis 
                    excepturi minima asperiores. </p>
                </div>
                <div className=" col-sm-12 col-md-6  mt-5">
                    <img src={vision} alt="mission img img-fluid    "  style={{width:'50px', height:'50px'}}  />
                    <p className='googleSans-bold h4' style={{ color: '#19154E', marginTop: '15px', fontWeight: 'bold' }}>Vision</p>
                    <p className='googleSans-normal '   style={{ fontSize:'16px', lineHeight:'31.6px', letter:'0.2px'}} >Lorem ipsum dolor, sit amet 
                    consectetur adipisicing elit. Quibusdam voluptatibus sed atque recusandae beatae 
                    dolorem debitis veniam in eos dolorum placeat magni unde, vitae et, sequi veritatis 
                    excepturi minima asperiores. </p>
                </div>
                </div>
</div>
                </div>
                

    
            </div>
            <div className="container">
                 <ForthPortion/>
            </div>
          
           
        </>
    )
}

export default About
