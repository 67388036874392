import React, { useState } from "react";
import NewFolderAdd from "./NewFolderAdd";
import { useTheme } from "../../ThemeProvider";
import "./Doc.css";

import { ReactComponent as Listicon } from "../../assets/icons/list.svg";
import { ReactComponent as Gridicon } from "../../assets/icons/grid.svg";
// import { ReactComponent as Approval } from  '../../assets/icons/approval.svg'

import DashButton from "../Buttons/DashButton";
import { UpdateUserDetail } from "../../redux/slice/auth";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";

export default function FolderAddButton({ setSearchTitle }) {
  const [addFolderModalOpen, setaddFolderModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  // const updatedData = useSelector((state) => state.user.userData.userDetail);
  const { theme } = useTheme();
  const updatedData = useSelector((state) => state.user.userData.userDetail);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);

  function addFolderModalScreen() {
    setaddFolderModalOpen(!addFolderModalOpen);
  }

  const searchData = (e) => {
    let searchTite = e.target.value;
    setSearchQuery(searchTite);
    setSearchTitle(searchTite);
  };

  const updatePersonalDetail = async (grid) => {
    if (updatedData?.data?.grid_view === grid) {
      return;
    } else {
      const formData = new FormData();
      formData.append("grid_view", grid);
      await dispatch(UpdateUserDetail(formData)).then((res) => {
        if (res.payload.message === "Success") {
          toast.success("View Applied Successfully!");
        }
      });
    }
  };

  return (
    <>
      <div className="p-3 mt-3 row">
        <div className="col-md-6 d-flex mb-md-0 mb-sm-3 mb-xs-3 mb-3">
          {/* <div className="d-flex align-items-center search-include">
            <SearchIcon className="mx-2 search-icon-color" />
            <input
              className=" folder-search"
              type="search"
              placeholder={`Search Folder...`}
              value={searchQuery}
              onChange={searchData}
              aria-label="Search"
            />
          </div> */}
        </div>

        <div className="col-md-6 d-flex justify-content-end">
          {/* <div className="d-flex justify-content-center me-3">
            <button
              style={{ border: "none", background: "transparent" }}
              onClick={() => updatePersonalDetail("list")}
              disabled={isLoading}
            >
              <Listicon
                style={{
                  cursor: "pointer",
                  fill: ` ${theme === "light" ? "black" : "white"}`,
                  width: "30px",
                }}
                className={``}
              />
            </button>
            <button
              onClick={() => updatePersonalDetail("grid")}
              style={{ border: "none", background: "transparent" }}
              disabled={isLoading}
            >
              <Gridicon
                style={{
                  cursor: "pointer",
                  fill: ` ${theme === "light" ? "black" : "white"}`,
                  width: "30px",
                }}
                className={`mx-1`}
              />
            </button>
          </div> */}

          <DashButton
            type={"button"}
            onClick={addFolderModalScreen}
            label={"Unsubscribe"}
            color="dark"
          />
        </div>
      </div>
      <NewFolderAdd
        closeModal={addFolderModalScreen}
        ModalIsOpen={addFolderModalOpen}
      />
    </>
  );
}
