import React, { useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import MenuIcon from "@mui/icons-material/Menu";
import LOGO from "../Images/zetLogo.jpeg";
import jwtDecode from "jwt-decode";
import "./sidebar.css";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";
import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import UserScreen from "../User/UserScreen";
import QuerScreen from "../Query/QuerScreen";
import DocScreen from "../Documents/FolderEnvirment/DocScreen";
// import CustomScreen from '../Customize/CustomScreen';
// import SetScreen from '../Setting/SetScreen';
import OrganizationsScreen from "../Organizations/OrganizationsScreen";
import DashTopNav from "./DashTopNav";
import { Typography } from "@mui/material";
import FilesScreen from "../Documents/FilesEnvirment/FilesScreen";
import { useTheme } from "../../ThemeProvider";
import UserProfile from "../Profile/UserProfile";
import SendQueryByUser from "../Query/SendQueryByUser";
import { useDispatch, useSelector } from "react-redux";
import { GetUserDetail } from "../../redux/slice/auth";
import Collaboration from "../Collaboration/Collaboration";
import UserSendedApprovalList from "../ApprovalListScreens/UserSendedApprovalList";
import TabOfApproval from "../ApprovalListScreens/TabOfApproval";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Complains from "../Query/Complains";
import { ReactComponent as Document } from "../../assets/icons/document.svg";
import { ReactComponent as User } from "../../assets/icons/Users.svg";
import { ReactComponent as Profile } from "../../assets/icons/Group.svg";
import { ReactComponent as Collaborationicon } from "../../assets/icons/barcode.svg";
import { ReactComponent as Query } from "../../assets/icons/Query.svg";
import { ReactComponent as Approval } from "../../assets/icons/approval.svg";
import { MdUnsubscribe } from "react-icons/md";
import Profiletab from "../Profile/Profiletab";
import SubscriptionScreen from "../SubscriptionDetails/SubscriptionScreen";
import SubscriptionDetailScreenForAdmin from "../SubscriptionDetailForAdmin/OrganizationsScreen";

export default function SideBar() {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [folderId, setFolderId] = useState(null);
  const { theme } = useTheme();
  var fldId = "";
  const handleFolderIdChange = (id) => {
    fldId = id;
    setFolderId(id);
  };

  const screenShift = () => {
    setButtonClicked(!buttonClicked);
  };

  let user = JSON.parse(localStorage.getItem("access-token"));
  const updatedData = useSelector((state) => state.user.userData.userDetail);

  const token = user;
  const decodedToken = jwtDecode(token);
  const userRole = decodedToken.role;

  const [defaultScreenValue, setdefaultScreenValue] = useState(
    userRole === "organization" || userRole === "user" ? "fourth" : "eight"
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      await dispatch(GetUserDetail());
    };
    getData();
  }, []);
  function handleSidebarClick() {
    setSidebarVisible(!sidebarVisible);
  }

  return (
    <>
      <div>
        <Tab.Container
          className={`${theme === "light" ? "" : "dark-background"}`}
          id="left-tabs-example"
          defaultActiveKey={defaultScreenValue}
        >
          <Row
            className={`m-0  ${theme === "light" ? "" : "dark-background "}`}
          >
            <Col
              sm={3}
              md={2}
              className={`p-0 shadow-end   sidebar-theme ${
                sidebarVisible ? "active" : ""
              } `}
              style={{ minHeight: "100vh" }}
            >
              <div className=" sidebar-content">
                <button
                  type="button"
                  onClick={handleSidebarClick}
                  className="text-start sideBar-icon bg-transparent border-0"
                >
                  <MenuIcon />
                </button>
                <div style={{ padding: "20px 0 0 0px", background: "#edd9d9" }}>
                  <div className="d-flex align-items-center justify-content-start mx-3">
                    <div className="d-flex justify-content-center">
                      <img
                        style={{ width: 45, height: 45, borderRadius: 10 }}
                        src={
                          updatedData?.data?.organizationLogo
                            ? updatedData?.data?.organizationLogo
                            : updatedData?.data?.logo
                            ? updatedData?.data?.logo
                            : LOGO
                        }
                        alt=""
                      />
                    </div>
                    <div className="text-center">
                      <span
                        className={`  mx-2 ${
                          theme === "light" ? "fw-bold" : "dark-text fw-bold"
                        }`}
                        style={{
                          margin: "10px 0",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        {updatedData?.data?.organizationName
                          ? updatedData?.data?.organizationName?.length > 20
                            ? updatedData?.data?.organizationName.slice(0, 20) +
                              "..."
                            : updatedData?.data?.organizationName
                          : updatedData?.data?.name
                          ? updatedData?.data?.name.length > 15
                            ? updatedData?.data?.name.slice(0, 15) + "..."
                            : updatedData?.data?.name
                          : `Zetta Circles User`}
                      </span>
                    </div>
                  </div>

                  <hr />
                </div>

                <div className="mt-0">
                  <Nav variant="pills" className="flex-column sidebar-theme">
                    {/* side menu titles */}
                    {userRole === "organization" ? (
                      <>
                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="fourth"
                          >
                            <p
                              color="secondary"
                              className="text-middle title m-0 my-2  "
                              id="btun"
                              style={{
                                // ,
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* <DescriptionOutlinedIcon style={{   }} /> */}
                              <Document className="custom-icon  mx-lg-3 mx-md-2" />

                              <p className="navbar-title m-0 p-0">
                                {" "}
                                Documents{" "}
                              </p>
                            </p>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="tweleth"
                          >
                            <p
                              color="secondary"
                              className="text-middle title m-0 my-2  "
                              id="btun"
                              style={{
                                // ,
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* <DescriptionOutlinedIcon style={{   }} /> */}
                              <Document className="custom-icon  mx-lg-3 mx-md-2" />

                              <p className="navbar-title m-0 p-0">
                                {" "}
                                Subscriptions{" "}
                              </p>
                            </p>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link "
                            eventKey="first"
                          >
                            <Typography
                              color="secondary"
                              className="text-middle title my-2 name"
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center", // Align the icon and text vertically
                              }}
                            >
                              {/* <AccountCircleIcon  />  */}
                              <Profile className="custom-icon  mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0">Profile </p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="second"
                          >
                            <Typography
                              color="secondary"
                              className=" text-middle title  my-2  "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* <PeopleAltOutlinedIcon  /> */}
                              <User className="custom-icon  mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0"> Users</p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="third"
                          >
                            <Typography
                              color="secondary"
                              className="text-middle title my-2  "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* <SearchOffOutlinedIcon  /> */}
                              <Query className="custom-icon  mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0"> Queries </p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="fifth"
                          >
                            <Typography
                              color="secondary"
                              className="text-middle title my-2  "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Collaborationicon className="custom-icon  mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0">
                                {" "}
                                Collaboration{" "}
                              </p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="tenth"
                          >
                            <Typography
                              color="secondary"
                              className="text-middle title my-2  "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Approval className="custom-icon  mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0">Approvals </p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    ) : userRole === "user" ? (
                      <>
                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="fourth"
                          >
                            <Typography
                              color="secondary"
                              className=" text-middle title my-2  "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Document className="custom-icon  mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0"> Documents</p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link "
                            eventKey="first"
                          >
                            <Typography
                              color="secondary"
                              className=" text-middle title my-2 name "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Profile className="custom-icon  mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0"> Profile </p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="fifth"
                          >
                            <Typography
                              color="secondary"
                              className="text-middle title my-2  "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/* <FolderSharedIcon  /> */}
                              <Collaborationicon className="custom-icon  mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0">
                                {" "}
                                Collaboration{" "}
                              </p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="tenth"
                          >
                            <Typography
                              color="secondary"
                              className="text-middle title my-2  "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Approval className="custom-icon  mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0">Approvals </p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="nineth"
                          >
                            <Typography
                              color="secondary"
                              className=" text-middle title my-2  "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Query className="custom-icon  mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0"> Query </p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    ) : userRole === "admin" ? (
                      <>
                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="eight"
                          >
                            <Typography
                              color="secondary"
                              className=" text-middle title my-2  "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <SupportOutlinedIcon className="mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0">
                                {" "}
                                Organization
                              </p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="eleventh"
                          >
                            <Typography
                              color="secondary"
                              className=" text-middle title my-2  "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <SearchOffOutlinedIcon className="mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0">
                                {" "}
                                Complains{" "}
                              </p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="firm-nav-item">
                          <Nav.Link
                            className="fs-6 d-flex firm-nav-link"
                            eventKey="thirteenth"
                          >
                            <Typography
                              color="secondary"
                              className=" text-middle title my-2  "
                              id="btun"
                              style={{
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <MdUnsubscribe className="mx-lg-3 mx-md-2" />
                              <p className="navbar-title m-0 p-0">
                                {" "}
                                Subscriptions{" "}
                              </p>
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    ) : (
                      ""
                    )}
                  </Nav>
                </div>
                {userRole === "organization" ? (
                  <div className="profile-options">
                    <DashTopNav className="ms-3" />
                  </div>
                ) : userRole === "user" ? (
                  <div className="profile-options">
                    <DashTopNav className="ms-3" />
                  </div>
                ) : (
                  <div className="profile-options">
                    <DashTopNav className="ms-3" />
                  </div>
                )}
              </div>
            </Col>
            {/* <Col sm={1} style={{ backgroundColor:`${theme==='light'? '#F8F8F8':'#041c2b'}`}}></Col> */}
            <Col
              sm={10}
              className="sidebar-screens "
              style={{ paddingTop: "30px" }}
            >
              <Tab.Content>
                <Tab.Pane style={{ color: "black" }} eventKey="first">
                  <Profiletab />
                </Tab.Pane>
                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="second"
                >
                  <UserScreen />
                </Tab.Pane>

                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="third"
                >
                  <QuerScreen />
                </Tab.Pane>
                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="fourth"
                >
                  {buttonClicked ? (
                    <FilesScreen
                      folderId={folderId}
                      onButtonClick={screenShift}
                    />
                  ) : (
                    <DocScreen
                      setFolderId={handleFolderIdChange}
                      onButtonClick={screenShift}
                    />
                  )}
                </Tab.Pane>

                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="tweleth"
                >
                  <SubscriptionScreen
                    folderId={folderId}
                    onButtonClick={screenShift}
                  />
                </Tab.Pane>

                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="fifth"
                >
                  <Collaboration />
                </Tab.Pane>

                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="sixth"
                ></Tab.Pane>

                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="eight"
                >
                  <OrganizationsScreen />
                </Tab.Pane>

                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="thirteenth"
                >
                  <SubscriptionDetailScreenForAdmin />
                </Tab.Pane>

                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="nineth"
                >
                  <SendQueryByUser />
                </Tab.Pane>

                <Tab.Pane
                  style={{ color: "black", border: "none" }}
                  eventKey="tenth"
                >
                  {/* <UserSendedApprovalList/> */}
                  <TabOfApproval />
                </Tab.Pane>

                <Tab.Pane eventKey="eleventh">
                  <Complains />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
}
