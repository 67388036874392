import React from 'react'
import landingPageImage from '../../Images/landingPageImage.png'
import './firstsection.css'
import BackgroundShade from '../../Images/BackgroundShade.png'
import aboutGradiant  from'../../Images/aboutGradiant.png';
import { useNavigate } from 'react-router-dom';
import { Navbarvalue } from '../../HeaderValue';


const FirstPortion = () => {

  const navigate=useNavigate()

  const {updateValue}=Navbarvalue()
  return (
   <>
    <div
        className="col  background-image"
        style={{
       
          backgroundImage: `url('${aboutGradiant}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "500px",
          display:"flex",
          alignItems:'center'
     
        }}
      >
        <div className="container " >
           <div className='  row' >
           <div className="row">
        <div className="col-md-6 text-start"> 
        <p className='appname mb-2 googleSans-medium fw-semibold' style={{color:"#19154E"}}>ZEETA CIRCLES</p>
        <p className='my-3 witoutgradian googleSans-bold'>Cloud <span className='gradiantext'> Document </span> <span className='gradiantext'> Managment</span> System</p>
        <p className='my-3 googleSans-medium' style={{color:"#19154E"}}>
        Transform your document-dependent work from a burdensome task to a powerful source of growth and positive impact.
        </p>

        <button style={{
                    backgroundColor: '#19154E',
                    marginTop: 3,
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    padding:'10px 30px',
                    
                   
                    
                }}
                className=' btn btn-lg googleSans-medium rounded-1' onClick={()=>{
                  navigate('/login')
                  updateValue(4)
                }}
                >Get Start Today</button>  
        </div>
        <div className="col-md-6 d-flex  align-items-center justify-content-center"
        
        style={{
          backgroundImage:`url(${BackgroundShade})`,
          backgroundSize:'cover',
          backgroundRepeat:'no-repeat'
        }}
        >
            <img className='img-fluid' src={landingPageImage} alt="" />
        </div>
    </div>
              
            </div>
        </div>
      </div>












  
   </>
  )
}

export default FirstPortion