import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import Form from "./Checkout";

const PUBLIC_KEY =
  "pk_test_51OCJC7GPnuTdSMMJ0onZ8qQm3SNcPA9D6nOUOsKOeVF7ViSoOXMiHyVleMfn8ueiGmMVNgO0ng6Co52r9KzvDdGH00y4pEsOlA";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

export default function StripePayment({ id }) {
  return (
    <Elements stripe={stripeTestPromise}>
      <Form ided={id} />
    </Elements>
  );
}
