
// Button.js

import React from 'react';
import PropTypes from 'prop-types';

const MainButton = ({ onClick, label, type, disabled,color, backgroundColor,borderColor }) => {
  return (
    <button
    className='mainButton button-font'
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={{
        padding: '0.8rem 2.6rem',
        fontSize: '18px',
        cursor: 'pointer',
        backgroundColor: `${backgroundColor}`,
        color: `${color}`,
        border: `1px solid ${borderColor}`,
        borderRadius: '5px',
      }}
    >
      {label}
    </button>
  );
};

MainButton.propTypes = {
  
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
};

MainButton.defaultProps = {
  type: 'button',
  disabled: false,
  backgroundColor:'#43425D',
  color:'white',
  borderColor:'#43425D'
};

export default MainButton;
