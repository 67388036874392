import React, { useEffect, useState } from "react";
import { Box, Container, LinearProgress, Typography } from "@mui/material";
import logo from "../../Images/Logo.png";
import iconLogo from "../../Images/Icon.png";
import arrow from "../../Images/arrow-up 1.png";
import device from "../../Images/Device.png";

const Loading = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const styles = {
    colorPrimary: {
      color: "green",
    },
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "50px auto",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={logo} alt="" />
        </Box>
        <Box
          sx={{
            mt: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={iconLogo} alt="" />
          <img src={arrow} alt="" />
          <img src={device} alt="" />
        </Box>
        <Box>
          <LinearProgress
            variant="determinate"
            value={progress}
            className={styles.colorPrimary}
            sx={{
              background: "rgba(40, 199, 111, 0.12)",
              borderRadius: "20px",
              padding: "5px 0px",
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#F80300",
              },
            }}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              textAlign: "center",
              color: "#808495",
              fontSize: "24px",
              mt: "20px",
            }}
          >
            Login Alternative Zetta Circles
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Loading;
