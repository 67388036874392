import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, label, Grid, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import Modal from "react-modal";
import { FormContentWrapper } from "../Organizations/organization.style";
import "./user.css";
import { InputFieldWrapperDiv } from "./user.style";
import { baseURL } from "../../axios/axios";
import { Country } from 'country-state-city';
import { MuiTelInput } from 'mui-tel-input'
import { useTheme } from '../../ThemeProvider';
import { useDispatch } from "react-redux";
import { GetOrgUserApi } from "../../redux/slice/organization";
import DashButton from "../Buttons/DashButton";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};



const UserModal = ({ modalIsOpen, closeModal }) => {

  const { theme } = useTheme();

  const [phone, setPhone] = React.useState('')
  const countriesList = Country.getAllCountries();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch()

  const handleChange = (newPhone) => {
    setPhone(newPhone)
  }

  const onSubmit = (data) => {
    if(data.password!= data.passwordConfirm){
      toast.error('Password and confirm password is not same')
      return
    }
    else{
          setLoading(true);
    let axiosConfig = {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("access-token")
        )}`,
      },
    };

    axios
      .post(`${baseURL}api/user/organization`, { ...data, phNo: phone }, axiosConfig)
      .then((res) => {
        if (res.status === 401 || res.status === 403) {
          toast.error(" Login expired! Please login again");
          // return localStorage.clear();
        }
        if (res.data.message == "Success") {
          toast.success("Successfully user created!");
          dispatch(GetOrgUserApi())
          setLoading(false);
          closeModal();
          reset()
          setPhone()
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        
        }
        setLoading(false);
      });
    }


  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Box sx={{ padding: "40px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom:'40px'
            }}
          >
             <Box>
            <h3
              className="bdms-title "

              style={{  color: `${theme === 'light' ? '#000' : 'white'}` }}
            >
              Create User
            </h3>
          </Box> 
            <IconButton onClick={closeModal} aria-label="delete">
              <CloseIcon sx={{ color: `${theme === 'light' ? '#000' : 'white'}` }} />
            </IconButton>
          </Box>
        
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <FormContentWrapper>
              <InputFieldWrapperDiv>
                <label className="form-label" sx={{ color: `${theme === 'light' ? '#000' : 'white'}` }}  >Email</label>
                <input

                  placeholder="Please Enter email"
                  type="email"
               

                  className="bdms-profile-input "
                  {...register("email", {
                    required: "email is required.",
                  })}
                />

                {errors.email && (
                  <div className="text-danger mb-2"  >{errors.email.message}</div>
                )}
              </InputFieldWrapperDiv>
              <InputFieldWrapperDiv>

                <label className="form-label" sx={{ color: `${theme === 'light' ? '' : 'white'}` }}  >Name</label>
                <input
                  placeholder="Please Enter Name"
                  type="text"
                  

                  className="bdms-profile-input"
                  {...register("name", {
                    required: "Name is required.",
                  })}
                />
                {errors.name && (
                  <div className="text-danger mb-2"  >{errors.name.message}</div>
                )}
              </InputFieldWrapperDiv>
            </FormContentWrapper>
            <FormContentWrapper>
              <InputFieldWrapperDiv>
                <label className="form-label" sx={{ color: `${theme === 'light' ? '' : 'white'}` }}  >Password</label>
                <input
                  placeholder="Please Enter Password"
                  type="password"

                  
                  className="bdms-profile-input"
                  {...register("password", {
                    required: "Password is required.",
                  })}
                />
                {errors.password && (
                  <div className="text-danger mb-2"  >
                    {errors.password.message}
                  </div>
                )}
              </InputFieldWrapperDiv>
              <InputFieldWrapperDiv>
                <label className="form-label" sx={{ color: `${theme === 'light' ? '' : 'white'}` }}  >Confirm Password</label>
                <input
                  placeholder="Please Enter Confirm Password"
                  type="password"

                  
                  className="bdms-profile-input"
                  {...register("passwordConfirm", {
                    required: "Confirm Password is required.",
                  })}
                />
                {errors.passwordConfirm && (
                  <div className="text-danger mb-2"  >
                    {errors.passwordConfirm.message}
                  </div>
                )}
              </InputFieldWrapperDiv>
            </FormContentWrapper>

            <FormContentWrapper>
              <InputFieldWrapperDiv>
                <label className="form-label" style={{ color: `${theme === 'light' ? '' : 'white'}` }}  >Phone</label>

                <MuiTelInput

                  style={{
                    backgroundColor: `${theme === 'light' ? '' : '#0c3a56'}`,
                    color: `${theme === 'light' ? '' : 'white'}`,
                    border: `${theme === 'light' ? '1px solid #C2C2C2 !important' : '1px solid white'}`,

                  }}
                  maxlength="12"
                  className="bdms-profile-input"
                  value={phone} onChange={handleChange} />


              </InputFieldWrapperDiv>
              <InputFieldWrapperDiv>
                <label className="form-label" style={{ color: `${theme === 'light' ? 'black' : 'white'}` }}  >Country</label>
                <select class=" country-selection bdms-profile-input" aria-label="Default select example" name='country'

                  style={{
                  color: `${theme === 'light' ? '' : 'white'}`,
                borderColor:'black'
                }}

                  {...register("country", {
                    required: "Country is required.",
                  })} >

                  {
                    countriesList?.map((item, i) => {
                      //  console.log(item,'city');
                      return (

                        <option key={i} className='text-muted' value={item.name}  >{item.name}</option>



                      )
                    })
                  }



                </select>
                {errors.country && (
                  <div className="text-danger mb-2"  >
                    {errors.country.message}
                  </div>
                )}
              </InputFieldWrapperDiv>



            </FormContentWrapper>

            <FormContentWrapper>
              <InputFieldWrapperDiv>
                <label className="form-label" sx={{ color: `${theme === 'light' ? '' : 'white'}` }}  >Address</label>
                <input
                  placeholder="Please Enter Address"
                  type="text"

                  
                  className="bdms-profile-input"
                  {...register("address", {
                    required: "Address is required.",
                  })}
                />
                {errors.address && (
                  <div className="text-danger mb-2"  >
                    {errors.address.message}
                  </div>
                )}
              </InputFieldWrapperDiv>
              <InputFieldWrapperDiv>
                <label className="form-label" sx={{ color: `${theme === 'light' ? '' : 'white'}` }}  >Industry</label>
                <input
                  placeholder="Please Enter Industry"
                  type="text"
                  className="bdms-profile-input"

                 

                  {...register("industry", {
                    required: "Industry is required.",
                  })}
                />
                {errors.industry && (
                  <div className="text-danger mb-2"  >
                    {errors.industry.message}
                  </div>
                )}
              </InputFieldWrapperDiv>
            </FormContentWrapper>

            <Box sx={{ mt: "40px" }}>

              <DashButton
                label={loading ? "Loading..." : "Add User"}
                type={'submit'}
                color='dark'

              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default UserModal;
