import React, { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import "./subscription.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { baseURL } from "../../axios/axios";
import StripePayment from "./StripeValidity";
import { useDispatch } from "react-redux";
import { GetUserDetail } from "../../redux/slice/auth";
const Subscription = () => {
  const [id, setId] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetUserDetail());
  }, []);

  const [checkout, setCheckout] = useState(false);
  const starter = [
    "1user",
    "3 TB of storage",
    "Larger file delivery up to 100GB",
    "3 TB of storage",
    "3 TB of storage",
    "PDF editing",
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const handleCheckout = () => {
    setCheckout(true);
  };
  async function fetchData() {
    const apiUrl = `${baseURL}api/stripe/plans`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      setId(data.data.data[0].id);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <>
      {checkout ? (
        <StripePayment id={id} />
      ) : (
        <div
          className="my-5"
          style={{
            background: "white",
            backgroundColor: "white !important",
          }}
        >
          <div className="d-flex w-100 text-center mt-5">
            <div className="w-100">
              <p className="fw-semibold" style={{ color: "#F90401" }}>
                {" "}
                START TODAY FOR FREE{" "}
              </p>

              <p
                className="  googleSans-bold"
                style={{ color: "#19154E", fontSize: "50px" }}
              >
                Choose your Plan{" "}
              </p>
              <p className="googleSans-medium" style={{ color: "#19154E" }}>
                {" "}
                Take as long as you need. No trial, no fees, no risk. Only
                upgrade <br />
                when you want to generate more AI content.
              </p>
            </div>
          </div>

          <div className="col-12">
            <div className="row justify-content-center mx-2">
              <div className="col-md-4 col-sm-6 col-xs-12 col-12">
                <div className="card  shadow rounded-4 subscription-card">
                  <div className="card-body" style={{ padding: "30px 40px" }}>
                    <p
                      className=" googleSans-bold my-3 "
                      style={{ color: "#F90401" }}
                    >
                      FOR PROFESSIONAL
                    </p>

                    {/* pricing */}
                    <div className="price mt-3">
                      <div className="d-flex">
                        <div className="doller">
                          <p
                            className=" googleSans-bold doller-text"
                            style={{ color: "#19154E" }}
                          >
                            $100/month
                          </p>
                        </div>
                      </div>
                    </div>
                    <p
                      className=" googleSans-bold fs-4"
                      style={{ color: "#19154E" }}
                    >
                      Essentials
                    </p>
                    {/* plan status */}
                    {/* <div className="my-3"> */}
                    {/* <button
                    className="btn w-100 googleSans-medium"
                    style={{ color: "white", background: "#19154E" }}
                  >
                    <div className="d-flex text-start align-items-center">
                      <div className="w-100">
                        <Link
                          to={"/Signup"}
                          style={{
                            color: "white",
                            textDecoration: "none",
                          }}
                        >
                          <p className="googleSans-bold fw-semibold m-0 ">
                            {" "}
                            Try for free
                          </p>
                        </Link>
                      </div>

                      <ArrowForwardIcon />
                    </div>
                  </button> */}
                    {/* </div> */}
                    {/* <div className="my-3">
                  <a
                    className="googleSans-medium"
                    style={{ color: "#F90401" }}
                    href="/Signup"
                  >
                    or buy now <ArrowForwardIcon />
                  </a>
                </div> */}
                    {/* package options  */}
                    <div className="mt-3 mb-3">
                      <div className="items-include mt-4">
                        {starter.map((item, i) => {
                          return (
                            <div className="d-flex my-2" key={i}>
                              <div>
                                <div
                                  className="rounded-circle "
                                  style={{ backgroundColor: "#19154E" }}
                                >
                                  <DoneIcon
                                    style={{
                                      color: "white",
                                      fontSize: "18px",
                                      margin: "0px 3px",
                                    }}
                                  />
                                </div>
                              </div>
                              <p className=" googleSans-medium mx-2 ">{item}</p>
                            </div>
                          );
                        })}

                        <div className="my-4">
                          <button
                            className="btn btn-lg   googleSans-medium w-100"
                            style={{
                              color: "white",
                              backgroundColor: "#F90401",
                            }}
                            onClick={() => handleCheckout()}
                          >
                            {/* <Link */}
                            {/* // to={"/Signup"} */}
                            {/* style={{
                            color: "white",
                            textDecoration: "none",
                          }}
                        > */}
                            Buy Now
                            {/* </Link> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscription;
