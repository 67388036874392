import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import './NavBar.css';
import EmailIcon from '@mui/icons-material/Email';
import logo from '../Images/Logo.png';
import {Link,useNavigate} from 'react-router-dom'
import  { Navbarvalue } from '../HeaderValue';



const Header = () => {
  const {navbarValue, updateValue}=Navbarvalue()
  const [showSidebar, setShowSidebar] = useState(false);

  const navigate = useNavigate()

    const user = JSON.parse(localStorage.getItem("access-token"))

  const handleFirstButtonClick = () => {
    navigate('/',{state:{scrollSection:'feature'}})
    updateValue(0)
  };

  const handleSecondButtonClick = () => {
    navigate('/',{state:{scrollSection:'price'}})
    updateValue(1)
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };




  return (
    <>
       <div className="d-flex w-100 align-items-center py-3 " style={{backgroundColor:'#19154E'}}> 
    <div className="container d-flex w-100 align-items-center">
          <p className='m-0 googleSans-medium ' style={{color:'white',fontSize:'16px',lineHeight:'16px'}}>Have any Questions? </p>
        <p className='m-0 px-3 googleSans-medium' style={{color:'orange',fontSize:"16px", lineHeight:'16px'}}> <EmailIcon/> info@zettacircles.com</p>
    </div>
      
    </div>


     <nav className="topnavbar">
      <div className="container d-flex align-items-center">
        <img className="logo img-fluid" onClick={()=>{
          updateValue(null)
          navigate('/')
        }} style={{ width: '200px',cursor:'pointer' }} src={logo} alt="" />
        <div className="topList pt-4">
          <ul>
            <li  onClick={handleFirstButtonClick}>
            
              <Link      className={`googleSans-bold fw-semibold ${
                navbarValue === 0 ? 'active' : ''
              }`} 
               style={{textDecoration:'none',color:` ${ navbarValue === 0 ? 'white' : 'black' }`, 
               padding: "10px 20px",
               fontSize:'16px',
               lineHeight:'16px'
               }}>Features</Link>
            </li>


            <li onClick={handleSecondButtonClick} >
            
            <Link      className={`googleSans-bold fw-semibold ${
              navbarValue === 1 ? 'active' : ''
            }`} to="/" 
             style={{textDecoration:'none',color:` ${ navbarValue === 1 ? 'white' : 'black' }`, 
             padding: "10px 20px",
             fontSize:'16px',
             lineHeight:'16px'
             }}>Pricing</Link>
          </li>
            <li >
              
              <Link 
                 className={`googleSans-bold fw-semibold ${
                  navbarValue === 2 ? 'active' : ''
              }`}
              onClick={() => updateValue(2)}
               to="/about" 
               style={{textDecoration:'none',color:` ${ navbarValue === 2 ? 'white' : 'black' }` ,
                padding: "10px 20px",
                fontSize:'16px',
                lineHeight:'16px'
                }}>About</Link>
            </li>
         
            <li
             
            >
             
              <Link 
                 className={`googleSans-bold fw-semibold ${
                  navbarValue === 3 ? 'active' : ''
              }`}
              onClick={() => updateValue(3)} 
              to="/contact" 
              style={{textDecoration:'none',color:` ${ navbarValue === 3 ? 'white' : 'black' }` ,
               padding: "10px 20px",
               fontSize:'16px',
               lineHeight:'16px'
               }}>Let'sTalk</Link>
            </li>
            <li
             
            >
             <Link 
                className={`googleSans-bold fw-semibold ${
                  navbarValue === 4 ? 'active' : ''
              }`}
              onClick={() => updateValue(4)} 
              to="/login" 
              style={{textDecoration:'none',color:` ${ navbarValue === 4 ? 'white' : 'black' }`,
               padding: "10px 20px",
              fontSize:'16px',
              lineHeight:'16px'
               }}>{user ? "Dashboard" : "Login"}</Link>
            </li>
          </ul>
        </div>
      </div>

      <MenuIcon className="menu-icon" onClick={toggleSidebar} />

      {showSidebar && (
        <div className="sidebar">
          <ul>
            <li><Link style={{textDecoration:'none'}}  className='googleSans-medium'   onClick={() => 
              {
                toggleSidebar()
              updateValue(0)
            }
              }   to='/'>Home</Link> </li>
            <li> <Link style={{textDecoration:'none'}} className='googleSans-medium'   onClick={() =>
             { toggleSidebar()
              updateValue(1)}
              }  to='/about'>About Us</Link> </li>
         
            <li> <Link style={{textDecoration:'none'}}  className='googleSans-medium'   onClick={() => 
             { updateValue(3)
              toggleSidebar()}
              }   to='/contact'>Contact Us</Link> </li>
            <li> <Link style={{textDecoration:'none'}}  className='googleSans-medium'   onClick={() => 
           {   toggleSidebar()
              updateValue(4)}
              }   to='/login'>{user ? "Dashboard" : "Login"}</Link> </li>

          </ul>
        </div>
      )}
    </nav>
    </>
   
  );
};

export default Header;
