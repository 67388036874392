import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SubscribeUser } from "../../redux/slice/auth";

const Form = ({ ided }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { state } = useLocation();
  const user_id = state ? state?.user_id : null;
  const planId = state ? state?.planId : null;
  const updatedData = useSelector((state) => state.user.userData.userDetail);

  const [data, setData] = useState({
    user_name: "",
  });

  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e, field) => {
    let value = e.target ? e.target.value : e;
    setData((prevData) => ({ ...prevData, [field]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  const submit = async () => {
    let formValid = true;
    const newErrors = {};

    if (data.user_name === "") {
      newErrors.user_name = "Name is required";
      formValid = false;
    }

    if (!formValid) {
      setErrors(newErrors);
      return;
    }

    if (formValid) {
      await stripe
        .createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: data.user_name,
          },
        })
        .then(async (resp) => {
          await dispatch(
            SubscribeUser({
              id: updatedData.data._id,
              planId: ided,
              paymentMethodId: resp?.paymentMethod?.id,
            })
          ).then((res) => {
            toast.success(
              res?.payload?.success === true
                ? "Subscribed Successfully!"
                : res?.payload?.message,
              { autoClose: 2000 }
            );
            if (res.payload.message === "success") {
              window.location.reload(navigate("/sidebar"));
            }
          });
          setData({ user_name: "" });
        });
    }
  };

  return (
    <Box className="my-5" style={{ background: "white" }}>
      <Container>
        <Grid
          container
          className="my-3"
          alignItems="center"
          sx={{
            background: "#fff",
            borderRadius: "8px",
            border: "3px solid #9f9f9f",
            boxShadow: "2px solid red",
            padding: { xs: "20px", lg: "20px 50px" },
          }}
        >
          <Grid item xs={12} md={6}>
            <Box sx={{ mt: "30px" }}>
              <Typography
                sx={{ fontSize: "24px", fontWeight: "600", color: "#1A1F36" }}
              >
                Credit/Debit Card Details
              </Typography>

              <Box component="form">
                <Box sx={{ mt: "20px" }}>
                  <label className="fw-semibold mb-2">
                    Name (as it appears on your card) (required)
                  </label>
                  <input
                    type="text"
                    className="w-100 px-3  p-2 rounded border"
                    name="cardName"
                    value={data.user_name}
                    onChange={(e) => handleChange(e, "user_name")}
                    autoComplete="off"
                    placeholder="Name"
                  />
                  {errors.user_name && (
                    <span
                      className="error text-danger mt-1"
                      style={{ fontSize: "14px" }}
                    >
                      {errors.user_name}
                    </span>
                  )}
                </Box>

                <Box sx={{ mt: "20px" }}>
                  <label className="fw-semibold mb-2">
                    Card number (required)
                  </label>
                  <CardNumberElement
                    id="card_number"
                    name="card_number"
                    className="payment-input border rounded-2 p-2  px-3"
                    options={{ placeholder: "#" }}
                  />
                </Box>

                <Box sx={{ mt: "20px" }}>
                  <label className="fw-semibold mb-2">
                    Expiration date (required)
                  </label>
                  <CardExpiryElement
                    id="account_expire"
                    autoComplete="off"
                    name="card_expiry"
                    className="payment-input py-2 px-3 p-2 border rounded"
                    options={{ placeholder: "MM/YYYY" }}
                  />
                </Box>

                <Box sx={{ mt: "20px" }}>
                  <label className="fw-semibold mb-2">
                    Security code (required)
                  </label>
                  <CardCvcElement
                    id="account_CVC"
                    name="card_cvc"
                    className="payment-input py-2 px-3 p-2 border rounded"
                    options={{ placeholder: "CVC" }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: "25px",
                  }}
                >
                  <Button
                    variant="contained"
                    type="button"
                    fullWidth
                    onClick={submit}
                    sx={{
                      color: "#fff",
                      background: "#709AA4",
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      "&:hover": { background: "#709AA4" },
                    }}
                  >
                    Pay
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Form;
