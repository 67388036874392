import React from "react";
// import Placeholder from '../Images/folder.png';
import img1 from "../Images/system.png";
import img2 from "../Images/Light.png";
import img3 from "../Images/Dark.png";
import { useTheme } from "../../ThemeProvider";
import { useDispatch } from "react-redux";
import { UpdateUserDetail } from "../../redux/slice/auth";
import toast from "react-hot-toast";

export default function AppearenceLayout() {
  const dispatch = useDispatch();
  const { theme, darkTheme, lightTheme } = useTheme();

  const updatePersonalDetail = async (color) => {
    if (theme === color) {
      return;
    } else {
      const formData = new FormData();
      formData.append("theme_colour", color);
      await dispatch(UpdateUserDetail(formData)).then((res) => {
        if (res.payload.message === "Success") {
          toast.success("Theme Changed Successfully!");
        }
      });
    }
  };

  return (
    <>
      <div
        className={`row row-cols-1 row-cols-md-3 g-4 ${
          theme === "light" ? "light-background" : "dark-background"
        }`}
      >
        {/* <div className="col">
          <div className="card">
            <img src={img1} className="card-img-top" alt="..." />
          </div>
          <div className="card-body">
            <h5 className={`card-title ${theme==='light'? 'dark-text':'light-text'}`} style={{ fontWeight: 550 }}>
              System
            </h5>
          </div>
        </div> */}
        <div className="col">
          <div
            className="card"
            type="button"
            onClick={() => {
              updatePersonalDetail("light");
              lightTheme();
            }}
          >
            <img src={img2} className="card-img-top" alt="..." />
          </div>
          <div className="card-body">
            <h5
              className={`card-title ${
                theme === "light" ? "dark-text" : "light-text"
              }`}
              style={{ fontWeight: 550 }}
            >
              Light
            </h5>
          </div>
        </div>
        <div className="col">
          <div
            className="card"
            type="button"
            onClick={() => {
              updatePersonalDetail("dark");
              darkTheme();
            }}
          >
            <img src={img3} className="card-img-top" alt="..." />
          </div>
          <div className="card-body">
            <h5
              className={`card-title ${
                theme === "light" ? "dark-text" : "light-text"
              }`}
              style={{ fontWeight: 550 }}
            >
              Dark
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}
