
import React from 'react'
import registor from '../../Images/registor.png'
import { useLocation, useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
const RegistrationNote = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const email = location.state.email
  return (
<>
<div className="d-flex align-items-center justify-content-center" style={{height:"100vh",backgroundColor:'#F5F5F5'}}>
    <div className="thankyou-card  rounded-1 shadow-sm" style={{backgroundColor:"white", minWidth:"500px"}}>
        <div className="clear d-flex justify-content-end align-items-center">
            <CloseIcon className='m-3' onClick={() => navigate('/login')} style={{cursor:"pointer"}}/>
        </div>
        <div className=""  style={{padding:"15px 50px"}}>
 <div className="title text-center">
            <p className='fw-bold' style={{fontSize:'25px',color:'#19154E'}} >Thanks for Registratoin!</p>
        </div>
        <div className="d-flex justify-content-center align-items-center">

             <img src={registor} alt="" />
        </div>
       
        <div className="text-center">
    <p className='fw-semibold m-0'>We have sent an email to</p>
    <p className='fw-semibold  m-0' style={{color:'blue'}}> {email ? email: 'info@gmail.com'}</p>
    <p className='fw-semibold m-0'>Please check your email</p>



            </div>

        </div>
       
        

    </div>

</div>
</>
  )
}

export default RegistrationNote