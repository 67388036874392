import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App.js';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';

import store from './redux/store.js';
import MultiStepFormContext from './components/Context/MultiStepFormContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {registerLicense} from '@syncfusion/ej2-base';
registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfVtdXGFWfFN0QXNcdV5xfldCcC0sT3RfQFljSn9QdkBjXH1edn1cQA==');
const queryClient = new QueryClient()


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <MultiStepFormContext>
          <Toaster
            position="top-center"
            reverseOrder={false}
          />
          <App />
        </MultiStepFormContext>
      </QueryClientProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
