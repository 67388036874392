import React, { useState } from "react";
import { ReactComponent as Folder1 } from "../../../assets/folderIcons/Folder1.svg";
import { ReactComponent as Folder2 } from "../../../assets/folderIcons/Folder2.svg";
import { ReactComponent as Folder3 } from "../../../assets/folderIcons/Folder3.svg";
import { ReactComponent as Folder4 } from "../../../assets/folderIcons/Folder4.svg";
import { ReactComponent as Folder5 } from "../../../assets/folderIcons/Folder5.svg";
import { ReactComponent as Folder6 } from "../../../assets/folderIcons/Folder6.svg";
import { ReactComponent as Folder0 } from "../../../assets/folderIcons/Folder0.svg";
import { ReactComponent as Deleteicon } from "../../../assets/icons/delete.svg";
import { ReactComponent as Editicon } from "../../../assets/icons/pencil.svg";

import "./foldertheme.css";
import { useSelector, useDispatch } from "react-redux";
import { GetAllFolder } from "../../../redux/slice/DocumentsCrudSlice";
import { useEffect } from "react";
import DeleteModal from "./DeleteModal";
import jwtDecode from "jwt-decode";
import { useTheme } from "../../../ThemeProvider";

export default function FolderListTable({
  sendDocIdToParent,
  filesScreen,
  searchTitle,
}) {
  const [allDoc, setallDoc] = useState();
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [docId, setdocId] = useState();

  const { theme } = useTheme();

  const folderImages = [
    Folder1,
    Folder2,
    Folder3,
    Folder4,
    Folder5,
    Folder6,
    Folder0,
  ];

  const getRandomFolderImage = () => {
    const randomIndex = Math.floor(Math.random() * folderImages.length);
    return folderImages[randomIndex];
  };

  const updatedAllDoc = useSelector(
    (state) => state.document.Documents.getAllFolder
  );

  const sendData = (id) => {
    sendDocIdToParent(id); // Call the callback function and pass the docId
  };

  let user = JSON.parse(localStorage.getItem("access-token"));

  const token = user;
  const decodedToken = jwtDecode(token);
  const organizationId = decodedToken.id;

  // modales popup functions start

  function DeleteModalScreen() {
    setDeleteModalOpen(!DeleteModalOpen);
  }

  // modales popup functions end

  //get all document api
  const dispatch = useDispatch();
  const getAllDoc = async () => {
    const response = await dispatch(GetAllFolder(organizationId));
    const getValues = response.payload;
    await setallDoc(getValues);
  };
  useEffect(() => {
    getAllDoc();
  }, []);

  useEffect(() => {
    if (updatedAllDoc) {
      setallDoc(updatedAllDoc);
    }
  }, [updatedAllDoc]);

  useEffect(() => {
    if (allDoc?.data && searchTitle) {
      const filteredResults = allDoc.data.filter((item) =>
        item.name.toLowerCase().includes(searchTitle.toLowerCase())
      );
      setallDoc((prev) => ({ ...prev, data: filteredResults }));
    } else {
      // Fetch the initial data or reset to original data when searchTitle is empty
      getAllDoc();
    }
  }, [searchTitle]);

  return (
    <>
      <div className=" border-0 mx-5 mt-2">
        <div className="card-body">
          <div className="table-responsive">
            <table
              style={{
                width: "100%",
              }}
            >
              <thead className="table">
                <tr>
                  <th
                    scope="col"
                    style={{ width: "20%" }}
                    className={`fw-bold ps-4 table-text`}
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    style={{ width: "20%" }}
                    className={`fw-bold  table-text`}
                  >
                    Name{" "}
                  </th>
                  {/* <th
                    scope="col"
                    style={{ width: "20%" }}
                    className={`fw-bold  table-text`}
                  >
                    Commit To Blockchain
                  </th> */}
                  <th
                    scope="col"
                    style={{ width: "20%" }}
                    className={`fw-bold  table-text`}
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    style={{ width: "20%" }}
                    className={`fw-bold text-center table-text`}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {allDoc?.data
                  ?.filter((item) => item.description !== "first folder")
                  ?.map((item, i) => {
                    return (
                      <>
                        <tr
                          className="table-record-row table-row-border table-row-background"
                          key={i}
                        >
                          <td>
                            <div
                              className="mt-3 ms-3"
                              onClick={() => {
                                filesScreen();
                                sendData(item.id);
                              }}
                              style={{
                                color: "black",
                              }}
                            >
                              {/* Use the dynamically generated SVG component */}
                              {React.createElement(getRandomFolderImage(), {
                                style: { width: "30px", cursor: "pointer" },
                              })}
                            </div>
                          </td>

                          <td>
                            <div className="">
                              <p
                                className={`table-text`}
                                type="button"
                                onClick={() => {
                                  filesScreen();
                                  sendData(item.id);
                                }}
                                style={{
                                  marginTop: 10,
                                  margim: 10,
                                  textTransform: "capitalize",
                                }}
                              >
                                {item?.name?.length > 20
                                  ? `${item?.name?.slice(0, 20)}...`
                                  : item?.name}
                              </p>
                            </div>
                          </td>

                          {/* <td>
                            <div className="  pt-3">
                              <button className={` d-flex table-text`}>
                                select
                              </button>
                            </div>
                          </td> */}

                          <td>
                            <div className="pt-3">
                              <p className={` d-flex table-text`}>
                                {item?.description?.length > 30
                                  ? `${item?.description?.slice(0, 30)}...`
                                  : item?.description}
                              </p>
                            </div>
                          </td>

                          <td>
                            <div className=" d-flex justify-content-center align-items-center">
                              <div className="">
                                <div className="icon-container py-2">
                                  {/* <div className="icon">  
                                  <Editicon 
                                  style={{ cursor: 'pointer',fill:` ${theme === 'light' ? 'black' : 'white'}` }}
                                 />
                                 </div> */}
                                  {/* <div className="vertical-line"></div> */}
                                  <div className="icon">
                                    {" "}
                                    <Deleteicon
                                      onClick={() => {
                                        DeleteModalScreen();
                                        setdocId(item.id);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        fill: ` ${
                                          theme === "light" ? "black" : "white"
                                        }`,
                                      }}
                                      className={` `}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Open modal */}

      <DeleteModal
        ID={docId}
        closeModal={DeleteModalScreen}
        ModalIsOpen={DeleteModalOpen}
      />
      {/* Open modal */}
    </>
  );
}
