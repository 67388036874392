

import React, { useState } from "react";
import {
    Box,
    IconButton,
 
  } from "@mui/material";

  import Modal from "react-modal";
  import CloseIcon from "@mui/icons-material/Close";
import InnerContent from "./InnerContent";
import EditorComponent from "./EditorComponent";



  
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "80%",
      height:"80%",

      transform: "translate(-50%, -50%)",
    },
  };
  
  const TextEditorWord = ({ EditorIsOpen, closeEditor,fileData,folderId }) => {



    return (
      <Box>
    
    <div className=" w-100 h-100">
       {/* <InnerContent/> */}
     <EditorComponent style={{width:'100%',height:'100%'}} fileData={fileData} folderId={folderId} closeEditor={closeEditor} EditorIsOpen={EditorIsOpen}/>
{/* <XlsxEditor style={{width:'100%',height:'100%'}} fileData={fileData} folderId={folderId}/>  */}
   </div>
   
            </Box>
        
    );
  };
  
  export default TextEditorWord;
  