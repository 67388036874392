
import {
    Box,
    IconButton,
    Typography,
    Button,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Modal from "react-modal";
  import CloseIcon from "@mui/icons-material/Close";
  import {useDispatch, useSelector } from "react-redux";
  import jwtDecode from 'jwt-decode';
  import { toast } from "react-hot-toast";
import { CreateNewFolder, GetAllFolder } from "../../../redux/slice/DocumentsCrudSlice";
import { useTheme } from '../../../ThemeProvider';
import DashButton from "../../Buttons/DashButton";
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "800px",
      transform: "translate(-50%, -50%)",
    },
  };
  
  const NewFolderAdd = ({ ModalIsOpen, closeModal }) => {

    const { theme} = useTheme();
    const {isLoading}=useSelector((state)=>state.document)
    const [validationMessages, setValidationMessages] = useState({
      name: "",
      description: "",
    });
    const [data, setdata] = useState({
        id:'',
        data:{
            name:'',
            description:"",
            parentFolderId:'64bea28f28a2be0a7d3b02c7',
        }
    })
    
  
  const dispatch = useDispatch();
   

  //extract organization id from token
  let user = JSON.parse(
    localStorage.getItem("access-token")
  )
  
  const token = user;
  const decodedToken = jwtDecode(token);
  const userId = decodedToken.id

//set organization to payload
  useEffect(()=>{
    setdata((prevData)=>({...prevData,id:userId}))
  },[])

  //get updated value of name of folder
  const handleNameChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        name: e.target.value
      }
    }));
    setValidationMessages((prevMessages) => ({
      ...prevMessages,
      name: e.target.value.trim() ? "" : "Folder Name is required",
    }));
  };

//get updated value of descrption of folder
  const handleDescriptionChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        description: e.target.value
      }
    }));
    setValidationMessages((prevMessages) => ({
      ...prevMessages,
      description: e.target.value.trim() ? "" : "Description is required",
    }));
  };
  

  const onSubmit =async()=>{
    if (!data.data.name.trim() && !data.data.description.trim()) {
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        name: "Folder Name is required",
        description: "Description is required",
      }));
     return
    }else  if (!data.data.description.trim()) {
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        description: "Description is required",
      }));
      return;
  
    } else if(!data.data.name.trim() ){
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        name: "Folder Name is required",
       
      }));
    } else{
   dispatch(CreateNewFolder(data))
.then((res)=>{
  if(res?.payload?.message){
    
    toast.success("Folder Created Successfully!")
    closeModal()
    setdata((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        description: '',
        name:''
      }
    }));
  }
  if(res?.error?.message){
    toast.error("Some Thing Wrong, Folder in Not Created")
  }
       
        dispatch(GetAllFolder(userId))
       
   

})   
    }

  }
  
    return (
      <Box >
        <Modal
          isOpen={ModalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Box sx={{ padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom:'40px'
              }}
            > 
             <h3  
              className="text-center bdms-title"
              style={{  color: `${theme==='light'? '#000':'white'}` }}>
                Add New Folder
              </h3>
              <IconButton onClick={()=>{
                closeModal()
                setdata((prevData) => ({
                  ...prevData,
                  data: {
                    ...prevData.data,
                    description: '',
                    name:''
                  }
      
                }))
                setValidationMessages((prevMessages) => ({
                  ...prevMessages,
                  name: "",
                  description: "",
                }));
              }} aria-label="delete">
              <CloseIcon sx={{ color: `${theme==='light'? '#000':'white'}` }} />
              </IconButton>


            </Box>
  
            <Box>
            
  
              <div class="form-group row my-3">
    <label for="inputPassword" class="col-sm-4  " style={{color:`${theme==='light'? '':'white'}`}}>Folder Name</label>
    <div class="col-sm-6">
      <input type="text"
       class="bdms-profile-input" 
      

       id="inputPassword"
        placeholder="Name"
        value={data.data.name}
        onChange={handleNameChange}
        />
             <div style={{ color: "red", fontSize: "12px" }}>
                {validationMessages.name}
              </div>
    </div>
  </div>

  <div class="form-group row mb-4">
    <label for="inputPassword" class="col-sm-4 "  style={{color:`${theme==='light'? '':'white'}`}}>Folder Description</label>
    <div class="col-sm-6">
    <textarea class="bdms-profile-input" 
    placeholder="Description"
     value={data.data.description}
   
     onChange={handleDescriptionChange}
    id="exampleFormControlTextarea1" 
    rows="3"></textarea>
         <div style={{ color: "red", fontSize: "12px" }}>
                {validationMessages.description}
              </div>
  </div>
  </div>
              <Box
              variant='div'
              
              >



  <DashButton
  onClick={onSubmit}
  color='dark'
  label={'Add'}
  disabled={isLoading}
  />

              </Box>
  
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  };
  
  export default NewFolderAdd;
  