
import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails,Typography, Button, Box } from '@mui/material';


const ForthPortion = () => {

    const [showSidebar, setShowSidebar] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [expanded, setExpanded] = useState(false)
    const handleChange = (isExpanded, panel) => {
        setExpanded(isExpanded ? panel : false)
    }

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const handleItemClick = (index) => {
        setActiveItem(index);
    };
  return (
   <>
    <div className="m-5 mx-2">
                <div className='mb-3 text-center'>
                    <p className='googleSans-bold'  style={{ textAlign: 'center', color: '#F90401', fontSize:'16px',lineHeight:'24px' }} >YOU NEED HELP?</p>
                    <p  className='googleSans-bold mb-5'  style={{ textAlign: 'center', color: '#19154E', marginTop: '15px', fontSize:'48px',lineHeight:'52.8px',letterSpacing:'-1px' }}>Frequently asked questions.</p>
                </div>

                <Accordion
                    expanded={expanded === 'panel1'}
                    style={{ backgroundColor: '#F5F9FD', marginBottom: '18px' }}
                    onChange={(event, isExpanded) => handleChange(isExpanded, 'panel1')}
                    className='rounded-5 p-2'
                >
                    <AccordionSummary
                        id='panel1-header'

                        aria-controls='panel1-content'
                        expandIcon={expanded ? <Typography variant='h5'>x</Typography> : <Typography variant='h5'>+</Typography>}
                    >
                        <p className='googleSans-bold mb-0' style={{ color: '#19154E',fontSize:'18px',lineHeight:'21.6px' }}>Why is automated document control important?</p>
                    </AccordionSummary>
                    <div className="px-3">
                        <p className='googleSans-medium' style={{ backgroundColor: '#F5F9FD', color: '#5B5E76',fontSize:'16px',lineHeight:'31.6px', letterSpacing:'0.2px' }}>
                        Document control is an essential part of any organization’s quality management
                         or compliance system. A manual or paper-based process for managing 
                         policies and documents is time-consuming, costly and prone to mistakes 
                         and security breaches. Traditional, onsite software also has disadvantages.
                    </p>
                    </div>
                </Accordion>


                <Accordion
                    expanded={expanded === 'panel2'}
                    style={{ backgroundColor: '#F5F9FD', marginBottom: '18px' }}
                    onChange={(event, isExpanded) => handleChange(isExpanded, 'panel2')}
                    className='rounded-5 p-2'
                >
                    <AccordionSummary
                        id='panel2-header'

                        aria-controls='panel2-content'
                        expandIcon={expanded ? <Typography variant='h5'>x</Typography> : <Typography variant='h5'>+</Typography>}
                    >
                        <p className='googleSans-bold mb-0' style={{ color: '#19154E',fontSize:'18px',lineHeight:'21.6px' }}>What is document control</p>
                    </AccordionSummary>
                    <div className="px-3">
                        <p className='googleSans-medium' style={{ backgroundColor: '#F5F9FD', color: '#5B5E76',fontSize:'16px',lineHeight:'31.6px', letterSpacing:'0.2px' }}>
                        Document control is an essential part of any organization’s quality management
                         or compliance system. A manual or paper-based process for managing 
                         policies and documents is time-consuming, costly and prone to mistakes 
                         and security breaches. Traditional, onsite software also has disadvantages.
                    </p>
                    </div>
                </Accordion>


                <Accordion
                    expanded={expanded === 'panel3'}
                    style={{ backgroundColor: '#F5F9FD', marginBottom: '18px' }}
                    onChange={(event, isExpanded) => handleChange(isExpanded, 'panel3')}
                    className='rounded-5 p-2'
                >
                    <AccordionSummary
                        id='panel3-header'
  
                        aria-controls='panel3-content'
                        expandIcon={expanded ? <Typography variant='h5'>x</Typography> : <Typography variant='h5'>+</Typography>}
                    >
                        <p className='googleSans-bold mb-0' style={{ color: '#19154E',fontSize:'18px',lineHeight:'21.6px' }}>What are important features od document control software?</p>
                    </AccordionSummary>
                    <div className="px-3">
                        <p className='googleSans-medium' style={{ backgroundColor: '#F5F9FD', color: '#5B5E76',fontSize:'16px',lineHeight:'31.6px', letterSpacing:'0.2px' }}>
                        Document control is an essential part of any organization’s quality management
                         or compliance system. A manual or paper-based process for managing 
                         policies and documents is time-consuming, costly and prone to mistakes 
                         and security breaches. Traditional, onsite software also has disadvantages.
                    </p>
                    </div>
                </Accordion>


                <Accordion
                    expanded={expanded === 'panel4'}
                    style={{ backgroundColor: '#F5F9FD', marginBottom: '18px' }}
                    onChange={(event, isExpanded) => handleChange(isExpanded, 'panel4')}
                    className='rounded-5 p-2'
                >
                    <AccordionSummary
                        id='panel4-header'

                        aria-controls='panel4-content'
                        expandIcon={expanded ? <Typography variant='h5'>x</Typography> : <Typography variant='h5'>+</Typography>}
                    >
                        <p className='googleSans-bold mb-0' style={{ color: '#19154E',fontSize:'18px',lineHeight:'21.6px' }}>What Kind of Customer Support is Available</p>
                    </AccordionSummary >
                    <div className="px-3">
                        <p className='googleSans-medium' style={{ backgroundColor: '#F5F9FD', color: '#5B5E76',fontSize:'16px',lineHeight:'31.6px', letterSpacing:'0.2px' }}>
                        Document control is an essential part of any organization’s quality management
                         or compliance system. A manual or paper-based process for managing 
                         policies and documents is time-consuming, costly and prone to mistakes 
                         and security breaches. Traditional, onsite software also has disadvantages.
                    </p>
                    </div>
                </Accordion>


                <Accordion
                    expanded={expanded === 'panel5'}
                    style={{ backgroundColor: '#F5F9FD', marginBottom: '18px' }}
                    onChange={(event, isExpanded) => handleChange(isExpanded, 'panel5')}
                    className='rounded-5 p-2'
                >
                    <AccordionSummary
                        id='panel5-header'

                        aria-controls='panel5-content'
                        expandIcon={expanded ? <Typography variant='h5'>x</Typography> : <Typography variant='h5'>+</Typography>}
                    >
                        <p className='googleSans-bold mb-0' style={{ color: '#19154E',fontSize:'18px',lineHeight:'21.6px' }}>What is document management?</p>
                    </AccordionSummary>
                    <div className="px-3">
                        <p className='googleSans-medium' style={{ backgroundColor: '#F5F9FD', color: '#5B5E76',fontSize:'16px',lineHeight:'31.6px', letterSpacing:'0.2px' }}>
                        Document control is an essential part of any organization’s quality management
                         or compliance system. A manual or paper-based process for managing 
                         policies and documents is time-consuming, costly and prone to mistakes 
                         and security breaches. Traditional, onsite software also has disadvantages.
                    </p>
                    </div>
                </Accordion>

                <Accordion
                    expanded={expanded === 'panel6'}
                    style={{ backgroundColor: '#F5F9FD', marginBottom: '18px' }}
                    onChange={(event, isExpanded) => handleChange(isExpanded, 'panel6')}
                    className='rounded-5 p-2'
                >
                    <AccordionSummary
                        id='panel6-header'

                        aria-controls='panel6-content'
                        expandIcon={expanded ? <Typography variant='h5'>x</Typography> : <Typography variant='h5'>+</Typography>}
                    >
                        <p className='googleSans-bold mb-0' style={{ color: '#19154E',fontSize:'18px',lineHeight:'21.6px' }}>Document control vs document management</p>
                    </AccordionSummary>
                    <div className="px-3">
                        <p className='googleSans-medium' style={{ backgroundColor: '#F5F9FD', color: '#5B5E76',fontSize:'16px',lineHeight:'31.6px', letterSpacing:'0.2px' }}>
                        Document control is an essential part of any organization’s quality management
                         or compliance system. A manual or paper-based process for managing 
                         policies and documents is time-consuming, costly and prone to mistakes 
                         and security breaches. Traditional, onsite software also has disadvantages.
                    </p>
                    </div>
                </Accordion>
               

            </div>
   </>
  )
}

export default ForthPortion