
// Button.js

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../../ThemeProvider';

const DashButton = ({ onClick, label, type, disabled,color, }) => {

  const { theme} = useTheme();

  return (
    <>
     {
      theme==='light'?
       <button
    className='DashButton btn button-font'
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={{
        padding: '0.4rem 1.5rem',
        fontSize: '18px',
        cursor: 'pointer',
        backgroundColor: `${color==='white'?"white":'#131D32'}`,
        color: `${color==='white'?'red':'white'}`,
        border:`1.5px solid ${color==='white'?'red':'#131D32'}`,
        borderRadius: '5px',
      }}
    >
      {label}
    </button>
    :

    <button
    className='DashButton button-font'
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={{
        padding: '0.6rem 2rem',
        fontSize: '18px',
        cursor: 'pointer',
        backgroundColor: `${color==='white'?"rgb(255 255 255 / 0%)":'#ffffff'}`,
        color: `${color==='white'?"#C0BDD6":'#030814'}`,
        border: `1px solid ${color==='white'? '#C0BDD6':'#030814'}`,
        borderRadius: '5px',
      }}
    >
      {label}
    </button>
    }
  
    </>
   

  
   
  );
};

DashButton.propTypes = {
  
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
};

DashButton.defaultProps = {
  type: 'button',
  disabled: false,
 
};

export default DashButton;
