import React, { useState } from 'react';
import ProForm from './ProForm';
import SecurityContent from '../ProfileOption/SecurityContent';
import AppearenceContent from '../ProfileOption/AppearenceContent';
import jwtDecode from 'jwt-decode';
import UserProfile from './UserProfile';

const Profiletab = () => {
  const [activeTab, setActiveTab] = useState('profile');
  let user = JSON.parse( localStorage.getItem("access-token"))

  const token = user;
  const decodedToken = jwtDecode(token);
  const userRole = decodedToken.role

  const highlighthandle = (id) => {
    // Remove 'active-profile-tab' class from all tabs
    document.querySelectorAll('.tab-item').forEach((tab) => {
      tab.classList.remove('active-profile-tab');
    });

    // Add 'active-profile-tab' class to the clicked tab
    document.getElementById(id).classList.add('active-profile-tab');

    // Update the activeTab state
    setActiveTab(id);
  };

  return (
    <>
    <div style={{marginBottom:'80px', marginTop:'20px'}}>
        <h3 className='px-5 bdms-title' >Edit Profile</h3>
    </div>
      <div className="tab-row">
        <div    className={`tab-item ${activeTab === 'profile' ? 'active-profile-tab' : ''}`}
        id="profile"
        onClick={() => highlighthandle('profile')}>
             <p >
          Edit Profile
        </p>
        </div>
        <div    className={`tab-item ${activeTab === 'security' ? 'active-profile-tab' : ''}`} 
        id="security"  
        onClick={() => highlighthandle('security')}>
              <p>
          Security
        </p>
        </div>
        {/* this is only for organization */}
        {userRole==='organization'?
         <div    className={`tab-item ${activeTab === 'appearance' ? 'active-profile-tab' : ''}`}
        id="appearance" 
        onClick={() => highlighthandle('appearance')}>
        <p>
          Appearance
        </p>
        </div>:
        ''
        }
       
       
      
      </div>
      <div className="show-screen">
        {activeTab==='profile'?
    userRole==='organization'?  <ProForm/>:<UserProfile/> 
    
    :

    activeTab==='security'?
    <SecurityContent/> 
    :
    userRole==='organization'?  <AppearenceContent/> :''
   
    }
      </div>
    </>
  );
};

export default Profiletab;
