import { Box, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import axios from "axios";
import { baseURL } from "../../../axios/axios";
import {
  GetAllFiles,
  ocrToFileApi,
} from "../../../redux/slice/DocumentsCrudSlice";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
//   import "../../Styles/uploadmusicModal.css"
// import { createWorker } from "tesseract.js";
import Tesseract from "tesseract.js";
import { Textarea } from "@mui/joy";
import { useTheme } from "../../../ThemeProvider";
import { ReactComponent as Scanicon } from "../../../assets/icons/scanfile.svg";
import { ReactComponent as Addfileicon } from "../../../assets/icons/addnewfile.svg";
import "./file.css";
import DashButton from "../../Buttons/DashButton";
import { set } from "react-hook-form";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "35%",
    maxHeight: "80%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
  },
};

const UPloadFile = ({ ModalIsOpen, closeModal, folderId }) => {
  const { theme } = useTheme();
  const dispatch = useDispatch();

  const [ocr, setOcr] = useState("");
  const [Loadingfile, setLoadingfile] = useState(false);
  const [Loadingscan, setLoadingscan] = useState(false);

  let user = JSON.parse(localStorage.getItem("access-token"));

  const token = user;
  const decodedToken = jwtDecode(token);
  const userId = decodedToken.id;
  const Org_id = decodedToken.organization;

  function convertLastModifiedDate(lastModifiedValue) {
    const timestamp = parseInt(lastModifiedValue);

    const date = new Date(timestamp);

    // Get the various components of the date
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();

    // Format the date and time
    const formattedDate = `${day}\\${month}\\${year}`;

    // Return the formatted date and time
    return `${formattedDate} `;
  }

  const completePayload = async (e) => {
    try {
      setLoadingfile(true);
      let uploadedFile = e.target.files[0];
      const lastModified = uploadedFile.lastModified;
      const formattedLastModified = convertLastModifiedDate(lastModified);

      let formdata = new FormData();
      formdata.append("doc", uploadedFile);
      formdata.append("folderId", folderId);

      formdata.append("name", uploadedFile.name);
      formdata.append("description", formattedLastModified);
      formdata.append("contentType", uploadedFile.type);

      await axios
        .post(`${baseURL}api/workDocs/document/${userId}`, formdata, {
          headers: {
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        })
        .then(() => {
          toast.success("file added successfully");
          dispatch(GetAllFiles({ org_id: userId, doc_id: folderId }));
        })

        .then(() => {
          setLoadingfile(false);
          closeModal();
        });
      // dispatch(getAllDocuments(userId));
    } catch (error) {
      console.error("Error uploading document:", error);
      toast.error("Error uploading document");
    }
  };

  const handleAddFileClick = () => {
    const addFileInput = document.getElementById("myFileInput");
    if (addFileInput) {
      addFileInput.click();
    }
  };

  const handleScanClick = () => {
    const scanDocInput = document.getElementById("scandoc");
    if (scanDocInput) {
      scanDocInput.click();
    }
  };

  const convertImageToText = async (e) => {
    setLoadingscan(true);
    let uploadedFile = e.target.files[0];

    if (!uploadedFile) {
      setLoadingscan(false);
      return;
    }

    try {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const imageDataUri = reader.result;
        if (!imageDataUri) return;
        const {
          data: { text },
        } = await Tesseract.recognize(imageDataUri);
        setLoadingscan(false);
        setOcr(text);
      };
      reader.readAsDataURL(uploadedFile);
      // setImageData(imageDataUri);
    } catch (error) {
      console.log(error);
    }
  };

  const convertTextToDoc = async () => {
    setLoadingscan(true);
    await dispatch(
      ocrToFileApi({
        ocr_text: ocr,
        folderId: folderId,
        organization: Org_id,
        user_id: userId,
      })
    ).then((res) => {
      if (res?.payload?.message === "success") {
        dispatch(GetAllFiles({ org_id: userId, doc_id: folderId }));
        setLoadingscan(false);
        closeModal();
        toast.success("file Upload successfully!");
        setOcr("");
      }
    });
  };

  return (
    <Box sx={{ width: "800px" }}>
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Box sx={{ padding: "20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <div className="ms-3 table-text">
              <p
                className="m-0"
                style={{ fontSize: "24px", fontWeight: "600" }}
              >
                Add New File
              </p>
            </div>
            <IconButton
              onClick={() => {
                closeModal();
                setOcr("");
                setLoadingscan(false);
                setLoadingfile(false);
              }}
              aria-label="delete"
            >
              <CloseIcon
                className={`${theme === "light" ? "dark-text" : "light-text"}`}
              />
            </IconButton>
          </div>

          <div className="row align-items-center justify-content-between text-dark my-2 px-4">
            {ocr?.length > 0 ? (
              <>
                <Textarea
                  value={ocr}
                  readOnly
                  style={{
                    justifyContent: "right",
                    backgroundColor: `${theme === "light" ? "" : "#0c3a56"}`,
                    color: `${theme === "light" ? "" : "white"}`,
                  }}
                />
                <div className="w-100 text-end mt-3">
                  <DashButton
                    label={Loadingscan ? "loading..." : "Upload"}
                    color="dark"
                    onClick={convertTextToDoc}
                    disabled={Loadingscan}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className="col-md-5 p-4 "
                  style={{ border: "2px dotted #CACACA" }}
                  type="button"
                  // onClick={handleAddFileClick}
                >
                  <div className="">
                    {/* <img src={newfileicon} alt="" style={{width:'50', height:'60px'}}/> */}
                  </div>
                  <div className="d-flex justify-content-center mt-2 d-flex align-items-center">
                    <input
                      type="file"
                      id="myFileInput"
                      className="hidden"
                      style={{ display: "none" }}
                      accept=".docx, .doc,.xlsx,.xls,.csv,.pdf, .jpeg, .jpg, .png, .pptx," // Add image and document file types
                      onChange={completePayload}
                    />
                    <label
                      style={{
                        display: "inline-block",
                        cursor: "pointer",

                        color: `${theme === "light" ? "" : "white"}`,
                        fontWeight: "700",
                      }}
                      htmlFor="myFileInput"
                      className="file-input-button btn-outline-dark rounded-2"
                    >
                      <div style={{ display: "grid" }}>
                        <div className="uploadicon d-flex justify-content-center mb-3">
                          <Addfileicon className="upload-icon-color" />
                        </div>

                        {Loadingfile ? "Loading..." : "+ Add New File"}
                      </div>
                    </label>
                  </div>
                </div>

                <div
                  type="button"
                  className="col-md-5 mt-1 p-4 "
                  style={{ border: "2px dotted #CACACA" }}
                  //  onClick={handleScanClick}
                >
                  <div className="d-flex justify-content-center mt-2 d-flex align-items-center">
                    <input
                      type="file"
                      id="scandoc"
                      className="hidden"
                      style={{ display: "none" }}
                      accept=".jpeg, .jpg, .png" // Add only image file types
                      onChange={convertImageToText}
                    />
                    <label
                      style={{
                        display: "inline-block",
                        cursor: "pointer",

                        color: `${theme === "light" ? "" : "white"}`,
                        fontWeight: "700",
                      }}
                      htmlFor="scandoc"
                      className="file-input-button  rounded-2"
                    >
                      <div style={{ display: "grid" }}>
                        <div className="uploadicon d-flex mb-3 justify-content-center">
                          <Scanicon className="upload-icon-color" />
                        </div>

                        {Loadingscan ? "loading..." : "Document Scan"}
                      </div>
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default UPloadFile;
