import React,{useState} from 'react';
import UPloadFile from './UploadFile';
import { useTheme } from '../../../ThemeProvider';
import { useDispatch, useSelector } from 'react-redux';
import DashButton from '../../Buttons/DashButton';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as Listicon} from '../../../assets/icons/list.svg';
import { ReactComponent as Gridicon} from '../../../assets/icons/grid.svg';
import { UpdateUserDetail } from '../../../redux/slice/auth';
import toast from 'react-hot-toast';



export default function FileAddButton({setSearchTitle,folderId}) {
  
  const [searchTite, setsearchTite] = useState()
  const   [uploadArtMusicModalOpen ,setUploadArtMusicModalOpen] = useState(false)

  const { theme } = useTheme();
  const { isLoading } = useSelector((state) => state.user)
  const updatedData = useSelector((state) => state.user.userData.userDetail);
const searchData = (e)=>{
  let searchTite=e.target.value;
  setSearchTitle(searchTite)

}
   const dispatch = useDispatch()

const uploadModalScreen = () => {
  setUploadArtMusicModalOpen(!uploadArtMusicModalOpen);
}

const updatePersonalDetail = async (grid) => {

  if (updatedData?.data?.grid_view === grid) {
    return
  } else {
    const formData = new FormData()
    formData.append("grid_view", grid)
    await dispatch(UpdateUserDetail(formData))
      .then((res) => {
        if (res.payload.message === 'Success') {
          toast.success('View Applied Successfully!')
        }
      })
  }

}

  return (
    <>
 <div className='p-3 my-5 row'>
      <div className="col-md-6 d-flex mb-md-0 mb-sm-3 mb-xs-3 mb-3">
  
  <DashButton type={'button'} 
 
   onClick={uploadModalScreen} 
  
    label={'Upload File'}
    color='white'
    />


      </div>


      <div className="col-md-6 d-flex justify-content-end">
      <div className='d-flex justify-content-center me-3'>
                  <button style={{ border: 'none', background: 'transparent' }} onClick={() => updatePersonalDetail('list')} disabled={isLoading}>
                    <Listicon style={{ cursor: 'pointer',fill:` ${theme === 'light' ? 'black' : 'white'}` ,width:'30px' }} className={``} />
                  </button>
                  <button onClick={() => updatePersonalDetail('grid')} style={{ border: 'none', background: 'transparent' }} disabled={isLoading}>
                    <Gridicon style={{ cursor: 'pointer',fill:` ${theme === 'light' ? 'black' : 'white'}`,width:'30px' }} className={`mx-1`} />
                  </button>

                </div>
    <form className="d-flex align-items-center search-include" role='search'>
     <SearchIcon  className='mx-2 search-icon-color'/>
      <input
        className=" folder-search"
        type="search"
        
        placeholder={` Search`}
        value={searchTite}
        onChange={searchData}
        aria-label="Search"
      />

    </form>




    </div>
 
    </div>
    <UPloadFile
    folderId={folderId}
        closeModal={uploadModalScreen}
        ModalIsOpen={uploadArtMusicModalOpen}
      />
   
    </>
  )
}