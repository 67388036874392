import { Box, Button, Input, Typography, styled } from "@mui/material";

export const ButtonWrapper = styled(Box)(({ theme }) => ({
    marginTop: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
}))

export const BackButton = styled(Button)(({ theme }) => ({
    border: "2px solid #ddd",
    textTransform: "capitalize",
    color: "rgba(128, 132, 149, 1)",
    "&:hover": {
        border: "2px solid #ddd",
    },
}))


export const NextButton = styled(Button)(({ theme }) => ({
    background: "rgba(38, 50, 56, 1)",
    textTransform: "capitalize",
    color: "#fff",
    "&:hover": {
        background: "rgba(38, 50, 56, 1)",
    },
}))

export const FormTitle = styled(Typography)(({ theme }) => ({
    fontSize: "24px",
    fontWeight: "600",
    color: "#808495",
    marginTop: "50px",
}))

export const SubTitle = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    fontWeight: "600",
    color: "rgba(1, 0, 45, 0.35)",
    marginTop: "20px",
}))

export const InputStyle = styled(Input)(({ theme }) => ({
    borderBottom: "2px solid #808495",
    color: "#808495",
    width: "100%",
    "input.MuiInputBase-input:placeholder": {
        fontWeight: "600"
    }

}))