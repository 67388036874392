import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Subscription from "../components/PaymentModule/Subscription";
import { useSelector } from "react-redux";

const Protected = (props) => {
  const { Component } = props;

  const updatedData = useSelector(
    (state) => state?.user?.userData?.userDetail || null
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const login = localStorage.getItem("access-token");
    if (!login) {
      navigate("/");
    }
  }, [navigate, dispatch]);

  const shouldShowSubscription = () => {
    if (!updatedData || Object.keys(updatedData).length === 0) {
      return false;
    }

    return (
      updatedData?.data?.role === "organization" &&
      (updatedData?.data?.subscriptionStatus === "incomplete" ||
        updatedData?.data?.subscriptionStatus === "incomplete_expired" ||
        updatedData?.data?.subscriptionStatus === "canceled")
    );
  };

  return <>{shouldShowSubscription() ? <Subscription /> : <Component />}</>;
};

export default Protected;
