import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import scaner from "../../Images/scener.png";

import logo from "../../Images/Logo.png";

import Otp from "../Otp/Otp";

import { Box, Container, IconButton, Typography, Button } from "@mui/material";

import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { RequestForTwoFA } from "../../redux/slice/auth";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "1100px",
    transform: "translate(-50%, -50%)",
  },
};

const QrCode = ({ ModalIsOpen, closeModal }) => {
  const dispatch = useDispatch();

  const [qrCodeValue, setqrCodeValue] = useState();
  const [RenderCodeScreen, setRenderCodeScreen] = useState(false);

  const getUrlForQr = async () => {
    const res = await dispatch(RequestForTwoFA());
    const value = res.payload;

    setqrCodeValue(value?.data);
  };

  useEffect(() => {
    getUrlForQr();
  }, []);
  // const qrCodeValue = {
  //   name: "Name: Mahin",
  //   email: "Email: mdmahin130@gmail.com",
  //   address: "Address: Bhuapur",
  //   password: "Password: 12345678",
  // };

  const codeScreen = () => {
    setRenderCodeScreen(!RenderCodeScreen);
  };

  return (
    <Box sx={{ width: "800px" }}>
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Box sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <IconButton onClick={closeModal} aria-label="delete">
              <CloseIcon sx={{ color: "#000" }} />
            </IconButton>
          </Box>
          {/* shift screen qrcode to otp screen */}
          {RenderCodeScreen ? (
            <Otp
              twoFAFormattedKey={qrCodeValue.twoFAFormattedKey}
              toTpURI={qrCodeValue.toTpURI}
            />
          ) : (
            <Container sx={{ margin: "20px auto" }}>
              <Box
                sx={{
                  mb: {
                    xs: "20px",
                    lg: "0px",
                  },
                }}
              >
                <img src={logo} alt="" />
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Box>
                  <Box sx={{ mb: "30px" }}>
                    <img src={scaner} alt="" />
                  </Box>
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "26px",
                        fontWeight: "600",
                        color: "#808495",
                        mb: "20px",
                      }}
                    >
                      Scan QR code
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#808495",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Scan this QR code in-app to verify a device
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      height: "240px",
                      margin: "0 auto",
                      maxWidth: 240,
                      width: "100%",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      padding: "10px",
                      mt: "20px",
                      mb: "56px",
                    }}
                  >
                    <QRCode
                      size={520}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={qrCodeValue?.toTpURI}
                      viewBox={`0 0 256 256`}
                    />
                  </Box>

                  {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              width: "100%",
            }}
          >
            <hr style={{ width: "20%" }} />
            <Typography variant="h6" sx={{ color: "#808495" }}>
              Verification Code
            </Typography>
            <hr style={{ width: "20%" }} />
          </Box>

          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: "30px",
              }}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<>&nbsp; &nbsp; &nbsp;</>}
                placeholder="0"
                inputStyle={{
                  width: "48px",
                  height: "48px",
                  borderRadius: "5px",
                  border: "2px solid rgba(67, 66, 93, 0.6)",
                  color: "rgba(67, 66, 93, 0.6)",
                  fontSize: "26px",
                }}
                renderInput={(props) => <input {...props} />}
              />
            </Box>
          </Box> */}

                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "4px",
                        mt: "20px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "#808495", fontSize: "14px" }}
                      >
                        Didn’t get a code?{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#226CFC",
                          fontSize: "14px",
                          textDecoration: "underline",
                        }}
                      >
                        Click to Resend
                      </Typography>
                    </Box>

                    <Button
                      variant="contained"
                      sx={{
                        background: "#263238",
                        color: "#fff",
                        textAlign: "center",
                        fontSize: "18px",
                        marginTop: "30px",
                        width: {
                          xs: "100%",
                          lg: "450px",
                        },
                        "&:hover": {
                          background: "#263238",
                        },
                      }}
                      onClick={codeScreen}
                    >
                      Continue
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Container>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default QrCode;
