import React from "react";
import foterlogo from "../Images/foterlogo.png";
import "./footer.css";
import { Link } from "react-router-dom";
import { Navbarvalue } from "../HeaderValue";

const MainFooter = () => {
  const { updateValue } = Navbarvalue();
  return (
    <footer className="fixed-footer w-100 d-flex align-items-center  ">
      <div className="d-flex container justify-content-between">
        <div className="d-flex align-items-center">
          <div>
            <img
              className="img-fluid"
              src={foterlogo}
              alt=""
              style={{ width: "200px" }}
            />{" "}
            <br />
            <small className="googlSans-normal text-muted">
              © zettacircles All rights reserved.
            </small>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <div>
            <ul className="footer-list">
              <li>
                <Link
                  style={{ textDecoration: "none" }}
                  className="googleSans-medium"
                  onClick={() => {
                    updateValue(null);
                    window.scroll(0, 0);
                  }}
                  to="/"
                >
                  {" "}
                  Home
                </Link>{" "}
              </li>
              <li>
                <Link
                  style={{ textDecoration: "none" }}
                  className="googleSans-medium"
                  onClick={() => {
                    updateValue(2);
                    window.scroll(0, 0);
                  }}
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  style={{ textDecoration: "none" }}
                  className="googleSans-medium"
                  onClick={() => {
                    updateValue(3);
                    window.scroll(0, 0);
                  }}
                  to="/contact"
                >
                  Let'sTalk
                </Link>
              </li>
              {/* <li>
                {" "}
                <Link
                  style={{ textDecoration: "none" }}
                  className="googleSans-medium"
                  to=""
                >
                  Policy
                </Link>
              </li> */}

              {/* <li> <Link to="#">Registration</Link></li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
