import { Box,  Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import UserModal from "./UserModal";
import UsersTable from "./UsersTable";
import { baseURL } from "../../axios/axios";
import { useTheme } from '../../ThemeProvider';
import TablePagination from "../pagination/Pagination";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { GetOrgUserApi } from "../../redux/slice/organization";
import DashButton from "../Buttons/DashButton";
import SearchIcon from '@mui/icons-material/Search';



export default function UserScreen() {


  const [dataForPagination, setdataForPagination] = useState([])

  const [searchTerm, setSearchTerm] = useState('');
   


 const dispatch = useDispatch()
  const { theme} = useTheme();
 const organizationUsers = useSelector((state)=>state.organization)


  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }


  
const paginatedlist=(userList) => {
  setdataForPagination(userList)
  }



  useEffect(()=>{
  
dispatch(GetOrgUserApi())
  },[dispatch])

  useEffect(()=>{
    if(organizationUsers){
      setdataForPagination(organizationUsers?.OrgUsers?.data?.result)
    }
  },[organizationUsers])


  useEffect(() => {
    if (searchTerm) {
      const filtered = organizationUsers?.OrgUsers?.data?.result?.filter(item =>
        item?.name.toLowerCase().includes(searchTerm.toLowerCase()));
      setdataForPagination(filtered);
    } else {
      setdataForPagination(organizationUsers?.OrgUsers?.data?.result);
    }
  }, [searchTerm, organizationUsers]);

  return (
    <>
      <div className=" my-1 px-md-5 m-0 " > 
      <h3
        className="bdms-title"             
             
                style={{ color:`${theme==='light'? '#272727':'white'}`, marginTop:'20px',marginBottom:'80px' }}
              >
                Users
              </h3>
        <div className="row">
  
          <Box
            sx={{
              mb: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
            <div className="d-flex align-items-center search-include" >
                  <SearchIcon  className='mx-2 search-icon-color' />
                  <input
                    className=" folder-search"
                    type="search"  
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}  
                    placeholder={`Search users...`}     
                    aria-label="Search"
                  />

                </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
           
              <DashButton
              color='dark'
              onClick={openModal}
              label={'Add User'}
              />
              



              {/* Modal */}
              <UserModal
                // refetch={refetch}
                closeModal={closeModal}
                modalIsOpen={modalIsOpen}
              />

            </Box>
          </Box>

        
          

          <div>
            <UsersTable   allUsers={dataForPagination}  />
          </div>
          {
            dataForPagination?.length>0 ?  <TablePagination list={dataForPagination} paginatedList={paginatedlist}/> : ''
          }
         
        </div>
      </div>
    </>
  );
}
