import {
  Box,
  IconButton,
  Typography,
  FormControl,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import { ShareDocument } from "../../redux/slice/DocumentsCrudSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useTheme } from '../../ThemeProvider';
import DashButton from "../Buttons/DashButton";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "50%",

    transform: "translate(-50%, -50%)",
  },
};

const InviteModal = ({ inviteModalIsOpen, closeInviteModal,fileData }) => {

  const dispatch = useDispatch();
  const { theme} = useTheme();
  const [emailError, setEmailError] = useState('');

  const [userData, setuserData] = useState({
    permission:'',
    email:''

  });

  //email  validatior
  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
 
  const SubmitData=()=>{
    if (!isEmailValid(userData.email)) {
      setEmailError('Please enter a valid email address');
      return;
    }
    if(userData.permission.length===0){
      toast.error("Please select permission")
      return
    }

    
    dispatch(ShareDocument({
     id:fileData._id,
     data:{sharedWith:userData?.email,permission:userData?.permission}
     
    }))
    .then((res)=>{
     if(res.payload.message==="success"){
       toast.success("successfully share document")
       setuserData({ 
         permission:[],
        email:''})
       closeInviteModal()
        
     }
   
    
  
    })
 }


  return (
    <Box sx={{ width: "800px" }}>
      <Modal
        isOpen={inviteModalIsOpen}
        onRequestClose={closeInviteModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Box sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
              <Typography variant="h4" sx={{ fontWeight: "600", color: `${theme==='light'? '#000':'white'}` }}>
              Invite People
            </Typography>
            <IconButton onClick={()=>{
              closeInviteModal()
              setuserData({ 
                permission:[],
               email:''})
            }} aria-label="delete">
            <CloseIcon sx={{color: `${theme==='light'? '#000':'white'}` }} />
            </IconButton>
          </Box>

          <Box>
          

            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "space-between",
                  gap: "30px",
                  mt: "20px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <label
                    style={{
                      fontWeight: "500",
                      color: "#000",
                      display: "block",
                      marginBottom:'5px'
                    }}
                  >
                    Enter Member Email
                  </label>
                  <input
                    type="email"
                    className="bdms-profile-input"
                    required
                    style={{
                      width: "100%",
                      border: "1px solid #ddd",
                      padding: "12px 16px",
                      borderRadius: "5px"
                     
                    }} 
                    value={userData?.email}
                    onChange={(e) => {
                      setuserData((prev) => ({ ...prev, email: e.target.value }));
                      setEmailError(''); // Reset error when user starts typing
                    }}
                  />
                  
                </Box>

                <Box>
                  <FormControl sx={{ width: "150px" }}>
                    <Select
                      displayEmpty
                      value={userData?.permission}
                      className="modal-table-background p-1"
                      sx={{
                       color:`${theme==='light'? '':'white'}`
                      }}
                      onChange={(e) => setuserData((prev)=>({...prev,permission:e.target.value}))}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <span
                              
                              style={{
                                fontWeight: "600",
                                fontSize: "18px",
                                color: "rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              Permission
                            </span>
                          );
                        }

                        return selected;
                      }}
                      // MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="Viewer">Viewer</MenuItem>
                      <MenuItem value="Contributer">Contributer</MenuItem>
                     
                    </Select>
                  </FormControl>
                </Box>
              </Box>
{emailError && <small style={{ color: 'red' }}>{emailError}</small>}
              <Box className='bdms-profile-input'
                sx={{
                 
                  padding: "20px",
                  marginTop: "20px",
                 
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "500", fontSize: "18px", color: `${theme==='light'? '#000':'white'}` }}
                  >
                    {userData?.email}
                  </Typography>

                  <Box>
                    <FormControl sx={{ border: "none" }}>
                    <Typography
                    variant="h6"
                    sx={{ fontWeight: "500", fontSize: "18px", color: `${theme==='light'? '#000':'white'}` }}
                  >
                    {userData?.permission? userData?.permission:''}
                  </Typography>
                    </FormControl>
                  </Box>
                </Box>
               
              </Box> 
              <div className="d-flex w-100 justify-content-end mt-3">

                  <DashButton
                  onClick={SubmitData}
                  label="Share"
                  color={'dark'}
                  />
                </div>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default InviteModal;
