// MyContextProvider.js
import React, { useContext, useState } from 'react';


import { createContext } from 'react';

const MyContext = createContext();

export const Navbarvalue = () => {
    return useContext(MyContext);
  };

const HeaderValue = ({ children }) => {
  const [navbarValue, setnavbarValue] = useState(null);

  const updateValue = (navbarValue) => {
    setnavbarValue(navbarValue);
  };

  return (
    <MyContext.Provider value={{ navbarValue, updateValue }}>
      {children}
    </MyContext.Provider>
  );
};

export default HeaderValue;
