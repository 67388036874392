import React from 'react'
import thankyou from '../../Images/thankyou.png'
import { useNavigate } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const Thankyou = () => {
    const navigate = useNavigate()
  return (
<>
<div className="d-flex align-items-center justify-content-center" style={{height:"100vh"}}>
    <div className="thankyou-card p-4 rounded-1 shadow-sm" style={{backgroundColor:"#F8F6EF"}}>
        <img src={thankyou} alt="" />
        <div className="ms-2 text-center">
    <p className='fw-bold' style={{fontSize:"20px"}}>You have verified your email</p>

<button className='btn ' style={{backgroundColor:"#19154E", color:"white"}} onClick={() => navigate('/login')}>
Continue   <span ><NavigateNextIcon /> </span>
</button>
            </div>
        

    </div>

</div>
</>
  )
}

export default Thankyou