import { Box, Button, IconButton, InputLabel, Typography, styled } from "@mui/material";

export const UpdatePasswordPageWrapper = styled(Box)(({ theme }) => ({
    height: "100vh",
    width: " 600px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    padding: "0px 20px",
}))

export const PasswordBoxWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "40px",
}))

export const ButtonWrapper = styled(Box)(({ theme }) => ({
    marginTop: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
}))


export const UpdateButton = styled(Button)(({ theme }) => ({
    textTransform: "capitalize",
    fontSize: "18px",
    color: "#19CB86",
    background: "#E9FBF2",
    padding: "10px 20px",
}))

export const CancelButton = styled(Button)(({ theme }) => ({
    textTransform: "capitalize",
    fontSize: "18px",
    color: "#808495",
}))

export const Title = styled(Typography)(({ theme }) => ({
    fontSize: "24px",
    fontWeight: "600",
    color: "#808495"
}))

export const InputLabelText = styled(InputLabel)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "600"
}))

export const IconButtonStyle = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    right: "20px",
    top: "5px"
}))