import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
// import { baseURL } from "../../axios/axios";
import { useTheme } from "../../ThemeProvider";
import { Country } from "country-state-city";
import { MuiTelInput } from "mui-tel-input";
import Avatar from "@mui/material/Avatar";

import { GetUserDetail, UpdateUserDetail } from "../../redux/slice/auth";
import DashButton from "../Buttons/DashButton";
import "./profile.css";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
export default function ProForm() {
  const [userDetails, setUserDetails] = useState({});
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.user);
  const updatedData = useSelector((state) => state.user.userData.userDetail);
  const { theme } = useTheme();
  const countryOptions = Country.getAllCountries();
  const [userImage, setuserImage] = useState(null);

  const fileInputRef = useRef(null);

  // Function to handle avatar click and trigger file input
  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setuserImage(file);
    }
  };

  const handleCountryCodeChange = (event) => {
    const selectedCountry = event.target.value;

    setUserDetails((prev) => ({
      ...prev,
      country: selectedCountry,
    }));
  };

  // get user data

  useEffect(() => {
    const getdetailsFun = async () => {
      const res = await dispatch(GetUserDetail());
      const value = res.payload;

      // localStorage.setItem('orgID',value.data.id);
      setUserDetails(value.data);
    };
    getdetailsFun();
  }, []);

  useEffect(() => {
    if (updatedData?.data) setUserDetails(updatedData?.data);
  }, [updatedData?.data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  // handle signUp
  const onSubmit = async (data) => {
    const keysToUpdate = [
      "name",
      "email",
      "address",
      "phNo",
      "industry",
      "country",
    ];

    const formdata = new FormData();

    // Append user image to the form data if it exists
    if (userImage) {
      formdata.append("logo", userImage);
    }

    // Assign missing values from userDetails to data (input fields)
    for (const key of keysToUpdate) {
      if (!data[key]) {
        data[key] = userDetails[key];
      }
    }

    // Append other form fields to the form data
    for (const key in data) {
      formdata.append(key, data[key]);
    }

    dispatch(UpdateUserDetail(formdata));

    try {
      // await dispatch(GetUserDetail());
      toast.success("Successfully updated data");
    } catch (error) {
      // Handle any potential error during dispatch(GetUserDetail())
      // and show an error toast or handle the error accordingly.
      console.error(error);
      toast.error("Failed to update data");
    }
  };

  // const passwordValidation = "^(?=.*d)[0-9]{8,}$";
  // password.current = watch("password", "");

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-11">
          <div className=" my-1 ">
            <div className="row">
              <div className="col-md-3 d-flex justify-content-start">
                <div className="logo-editor">
                  <div className="org-logo " onClick={handleAvatarClick}>
                    <div className="">
                      <input
                        className=""
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept="image/*"
                      />

                      <Avatar
                        alt="Remy Sharp"
                        src={
                          userDetails?.logo && !userImage
                            ? userDetails?.logo
                            : userImage
                            ? URL.createObjectURL(userImage)
                            : !userImage && !userDetails?.logo
                            ? "https://bootdey.com/img/Content/avatar/avatar7.png"
                            : ""
                        }
                        style={{ color: "red", height: 130, width: 130 }}
                      />
                      {/* <img

                  src={
                    userDetails?.logo && !userImage ? userDetails?.logo :
                      userImage ? URL.createObjectURL(userImage) :
                        !userImage && !userDetails?.logo ? 'https://bootdey.com/img/Content/avatar/avatar7.png' : ''
                  }


                  className="img-fluid"
                 
                  alt="Avatar"
                /> */}
                    </div>

                    <div className="camera-icon">
                      <div
                        className="p-2 bg-white rounded-circle shadow-sm "
                        style={{ color: "gray" }}
                      >
                        <CameraAltIcon style={{ fontSize: "28px" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 p-0">
                <div className="setting" style={{}}></div>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className=" g-2 form"
                  method="POST"
                >
                  <div className="row" style={{ padding: "0 12px" }}>
                    <div className="col-md-6 p-0 pe-2">
                      <div className="mb-4 mt-0">
                        <label
                          className="form-label"
                          style={{
                            color: `${theme === "light" ? "#8f8e8e" : "white"}`,
                          }}
                        >
                          Organization Name
                        </label>
                        <br />
                        <input
                          className="bdms-profile-input"
                          defaultValue={userDetails?.name}
                          {...register("name")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 p-0 ps-2">
                      <div className="mb-4">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          className="bdms-profile-input"
                          id="exampleFormControlInput1"
                          defaultValue={userDetails?.email}
                          readOnly
                          {...register("email")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-4">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      >
                        Address
                      </label>
                      <input
                        className="bdms-profile-input"
                        placeholder="Enter Address"
                        id="exampleFormControlInput1"
                        defaultValue={userDetails?.address}
                        {...register("address")}
                      />
                      {/* {errors.address && errors.address.type === "required" && (
                <div className="text-danger mt-2">Address is required</div>
              )} */}
                    </div>
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Contact Number
                    </label>
                    {/* <input
              placeholder="Enter Contact Number"
             
                type="number"
               
               
                id="exampleFormControlInput1"
              
              />  */}

                    <input
                      type="tel"
                      id="phone"
                      className="bdms-profile-input"
                      name="phone"
                      placeholder="03xx-xxxxxxx"
                      // pattern="[0-9]{4}-[0-9]{7}"
                      required
                      maxLength="11"
                      defaultValue={userDetails?.phNo}
                      {...register("phNo")}
                    />
                  </div>

                  <div className=" mb-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Industry
                    </label>
                    <input
                      placeholder="Enter Industry Name"
                      type="text"
                      className="bdms-profile-input"
                      id="exampleFormControlInput1"
                      defaultValue={userDetails?.industry}
                      {...register("industry")}
                    />
                  </div>

                  <div className=" mb-4 ">
                    <label className="form-label">Country</label>

                    <select
                      style={{
                        color: `${theme === "light" ? "" : "white"}`,
                        width: "100%",
                        height: 50,
                        borderRadius: "5px",
                        border: "1px solid #C2C2C2",
                        padding: "0 10px",
                      }}
                      name="country"
                      className="country-select  bdms-profile-input"
                      value={userDetails?.country}
                      onChange={handleCountryCodeChange}
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countryOptions.map((country) => (
                        <option key={country.isoCode} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>

                    {errors.city && (
                      <div>
                        <div className="text-danger mt-2">
                          Country name is required
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-auto">
                    <DashButton
                      color="dark"
                      type={"submit"}
                      label={isLoading ? "Loading..." : "Save"}
                      disabled={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
