import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { MDBCardImage, MDBRow, MDBCol } from "mdb-react-ui-kit";
import img1 from "../Login/images/img1.png";
import img2 from "../Login/images/img2.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";

import { useDispatch, useSelector } from "react-redux";
import { signInUser } from "../../redux/slice/auth";
import MainButton from "../Buttons/MainButton";
import Cookies from "js-cookie";

const Login = () => {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [emailValidator, setemailValidator] = useState("");
  const [passwordValidator, setpasswordValidator] = useState();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const handleClick2 = () => {
    navigate("/Signup");
  };
  const handleClick3 = () => {
    navigate("/forgetpassword");
  };
  const styles = {
    height: "800px",
  };
  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
  // }));
  // ==========validation functionality start===========

  const password = useRef({});
  const { handleSubmit, watch } = useForm();

  // redux action dispatch
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);

  const onSubmit = () => {
    if (data.password.length <= 0 && data.email.length <= 0) {
      setpasswordValidator("Password is required");
      setemailValidator("Email is required");
      return;
    }
    if (data.password.length <= 0) {
      setpasswordValidator("Password is required");
      return;
    }
    if (data.email.length <= 0) {
      setemailValidator("Email is required");
      return;
    } else {
      setemailValidator("");
      setpasswordValidator("");
    }

    dispatch(signInUser(data)).then(() => {
      if (rememberMe === false) {
        Cookies.remove("userPass");
        Cookies.remove("useremail");
      } else {
        Cookies.set("userPass", data.password, { expires: 30 }); // Store password for 30 days
        Cookies.set("useremail", data.email, { expires: 30 }); // Store username for 30 days
      }
    });
  };

  useEffect(() => {
    let login = localStorage.getItem("access-token");
    if (login) {
      navigate("/sidebar");
    }
  });

  // const passwordValidation = "^(?=.*d)[0-9]{8,}$";
  password.current = watch("password", "");
  // ==========validation functionality end===========

  useEffect(() => {
    const storedUserEmail = Cookies.get("useremail");
    const storedPassword = Cookies.get("userPass");

    if (storedUserEmail && storedPassword) {
      setData({
        ...data,
        email: storedUserEmail,
        password: storedPassword,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="my-1">
        <div className="row g-0">
          <div className="col-md-6 order-md-1 order-sm-2 d-none d-sm-block">
            <MDBCardImage
              src={img2}
              alt="login form"
              className="rounded-start w-100 img-fluid"
              style={styles}
            />
          </div>

          <div className=" col-md-6 order-md-2 order-sm-1">
            <div className="d-flex justify-content-center align-items-center">
              <div className="">
                <div className="d-flex flex-row mt-2 align-items-end custom-login">
                  <img
                    src={img1}
                    alt=""
                    style={{ height: 90, width: 73, marginLeft: "20px" }}
                  />
                  <span
                    className=" logo-font   ml-5"
                    style={{
                      color: "#43425D",
                      fontSize: "48px",
                      fontWeight: "400",
                    }}
                  >
                    Zetta Circles
                  </span>
                </div>

                <p
                  className="fw-normal my-4  text-center montserrat-font"
                  style={{
                    fontSize: "18px",
                    lineHeight: "47px",
                    letterSpacing: "-0.5px",
                  }}
                >
                  Welcome Back! Please login to your account.
                </p>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ padding: "0 30px" }}
                >
                  <Grid container>
                    <TextField
                      autoComplete="off"
                      id="standard-basic"
                      label="Email"
                      variant="standard"
                      fullWidth
                      type="email"
                      value={data.email}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          email: e.target.value,
                        }));
                        setemailValidator("");
                      }}
                      sx={{ margin: "10px 0", color: "red" }}
                      // {...register("email", {
                      //   required: "Email is required.",
                      // })}
                    />
                    {emailValidator && (
                      <small style={{ color: "red" }}>{emailValidator}</small>
                    )}
                  </Grid>
                  <Grid container>
                    <TextField
                      id="standard-password-input"
                      label="Password"
                      type="password"
                      autoComplete="off"
                      variant="standard"
                      value={data.password}
                      onChange={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          password: e.target.value,
                        }));
                        setpasswordValidator("");
                      }}
                      fullWidth
                      style={{ marginTop: "5px", marginBottom: "22px" }}
                    />
                    {passwordValidator && (
                      <small style={{ color: "red" }}>
                        {passwordValidator}
                      </small>
                    )}
                  </Grid>
                  {/* 2 column grid layout for inline styling */}
                  <div className="row my-5">
                    <div className="col ">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={() => setRememberMe(true)}
                          id="form1Example3"
                        />
                        <p
                          className="form-check-label m-0 montserrat-font"
                          htmlFor="form1Example3"
                        >
                          Remember me
                        </p>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-end">
                      <a
                        href="#!"
                        className="text-dark montserrat-font"
                        style={{ textDecoration: "none" }}
                        onClick={handleClick3}
                      >
                        Forgot password?
                      </a>
                    </div>
                  </div>
                  {/* =====login / sign up button======= */}
                  <MDBRow className="d-flex justify-content-between">
                    <MDBCol>
                      <MainButton
                        type={"submit"}
                        backgroundColor={"rgb(25, 21, 78)"}
                        color={"white"}
                        label={isLoading ? "Loading..." : "Login"}
                      />
                    </MDBCol>

                    <MDBCol className="d-flex justify-content-end">
                      <MainButton
                        backgroundColor={"white"}
                        color={"#4D4F5C"}
                        label={"SignUp"}
                        onClick={handleClick2}
                      />
                    </MDBCol>
                  </MDBRow>
                </form>
              </div>
            </div>

            {/* <div className=" text-center mt-5">
              {" "}
              <small className="text-center montserrat-font  fs-6">
                Term of use{" "}
                <span>
                  {" "}
                  <Link style={{ textDecoration: "none" }} to="/login">
                    {" "}
                    Privacy Policy
                  </Link>
                </span>{" "}
              </small>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
