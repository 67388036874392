import { Box } from "@mui/material";
import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { IconButton, Tooltip } from "@mui/material";

import "./Doc.css";
import {
  BackButton,
  ButtonWrapper,
  FormTitle,
  NextButton,
} from "./document.style";
import { useTheme } from '../../ThemeProvider';

import { MULTI_STEP_CONTEXT } from "../Context/MultiStepFormContext";
import DashButton from "../Buttons/DashButton";
import { useDispatch } from "react-redux";
import { CheckEmailValidationApi } from "../../redux/slice/auth";

const FormTabTwo = ({ handleNext, handleBack, classes, activeStep }) => {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const { theme} = useTheme();
  const dispatch = useDispatch()

  const { setInputTag, inputTag, setDateAndTime, dateAndTime } =
    useContext(MULTI_STEP_CONTEXT);

    const [showTooltip, setShowTooltip] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value.trim() === "") {
      setShowTooltip(false);
    }
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const newTag = inputValue.trim();
  

      if (newTag && !tags.includes(newTag)) {
    dispatch(CheckEmailValidationApi({email:newTag}))
      .then((res)=>{
        if(res?.payload?.message==='Success'){
            setTags([...tags, newTag]);
        setInputTag([...tags, newTag]);
        }

      })
      
      }

      setInputValue("");
    } else if (event.key === "Backspace" && inputValue === "") {
      setTags(tags.slice(0, -1));
    }
  };

  const handleTagDelete = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  
  return (
    <Box>


      <Box component="form">
        <Box>
          <label style={{ marginBottom: "10px" }} className="label">
          Emails List
          </label>

          <Box >
            {/* <TextField
              label="Emails"
            
             class="modal-body-color table-text email-list"
             
              fullWidth
            /> */}
            <input type="email" className="bdms-profile-input"  value={inputValue}  onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}/>
           
          </Box> 
          {
            !showTooltip && inputValue && <h6 className="mt-2 p-1" style={{fontSize:'12px'}}>Press Enter to add email in list</h6>
          }
          <Box sx={{  top: "0px", display: "block", marginTop:'10px'}}>
              {tags.map((tag, index) => (
                <Chip
                  key={index}
                  sx={{color:`${theme==='light'? '':'white'}`}}
                  label={tag}
                  value={inputTag}
                  onDelete={handleTagDelete(tag)}
                  style={{ marginRight: "5px" }}
                />
              ))}
            </Box>
        </Box>

     

        <ButtonWrapper>
       
        
  <DashButton
    disabled={activeStep === 0}
            onClick={handleBack}
            label="Back"
            color={'white'}
  />


          {/* <button
          
            className={`btn   m-2  ${theme==='light'? 'btn-dark':'btn-primary'}`}
           
          
          >
            Next
          </button> */}
       {tags.length > 0 ? 

       <DashButton
            onClick={handleNext}
            type="submit"
            
            color={'dark'}
            label="Next"
          />
          :
          <Tooltip title='Press Enter to add email in list'>
          <IconButton className="p-0 ">
          <DashButton
            onClick={handleNext}
            type="submit"
            disabled
            color={'dark'}
            label="Next"
            
          /> 
            </IconButton></Tooltip>
       }
     
          
       
        </ButtonWrapper>
      </Box>
    </Box>
  );
};

export default FormTabTwo;
