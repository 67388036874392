import React, { useState } from 'react'
import "./contact.css"
import mail from "../../Images/call1.png";
import msg from "../../Images/msg.png";
import time from "../../Images/Time-icon.png";
import { TextField } from "@mui/material";
import {toast} from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux';
import { AddContactInfoApi } from '../../redux/slice/ContactSlice';



const ContactUs = () => {

  const [Data, setData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    city: '',
    message: ''
  })

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    city: '',
    message: ''
  });
  const {isLoading}=useSelector((state)=>state.contact)
   const dispatch = useDispatch()
  const handleInputChange = (field, value) => {
    setData({ ...Data, [field]: value });
    setErrors({ ...errors, [field]: '' });
  };



  const submit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const newErrors = {};

    if (!Data.name) {
      newErrors.name = 'Please enter your name';
    }

    if (!Data.email || !emailRegex.test(Data.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    if (!Data.phoneNumber) {
      newErrors.phoneNumber = 'Please enter your phone number';
    }

    if (!Data.city) {
      newErrors.city = 'Please enter your city';
    }

    if (!Data.message) {
      newErrors.message = 'Please enter your message';
    }
    if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }

      dispatch(AddContactInfoApi(Data))
      .then((res)=>{
if(res?.payload?.message==='Success'){
   toast.success('Sent Message Successfully');
         setData({
            name: '',
            email: '',
            phoneNumber: '',
            city: '',
            message: ''
          })
}
        
      })
   
  };



  return (
<>


{/* <div className='container'> */}
<div className='row p-0 m-0 mt-4'>
  
        <div className='col-md-6  pt-md-5'>
            <div className="container px-md-5">
       <h3 className='head-contact googleSans-bold'>Leave us a message</h3>
<h3 className='subhead-contact googleSans-medium'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. tibulum et tincidunt quam. Phasellus elit nisl, semper viverra congue eu, fermentum ut mi.</h3>
<div className='row'>
  <div className='col-md-4 ' style={{textAlign:"center"}}>
<img src={msg} alt=""  />
<div className='d-flex justify-content-center h-100'>
<p className='paragraph-contact googleSans-medium'>info@zettacircles.com</p>
</div>

  </div>
  <div className='col-md-4' style={{textAlign:"center"}}>
  <img src={mail} alt="" />
  <div className='d-flex justify-content-center h-100'>
     <p className='paragraph-contact googleSans-medium'> (00) 112 365 489</p>
  </div>
 
    </div>
    <div className='col-md-4' style={{textAlign:"center"}}>
    <img src={time} alt="" />
    <div className='d-flex justify-content-center h-100'>
       <p className='paragraph-contact googleSans-medium'>Mon - Sat 9.00 - 18.00 
  <div className='d-flex justify-content-center'>Sunday Closed</div> </p>
    </div>
   
    </div>
    <div className='row mx-0'>
      <div className='col-md-6 mt-3'>
      <TextField
           label="Your Name"
            
            value={Data.name}
            name='name'
          
            onChange={(e) => handleInputChange('name', e.target.value)}
            variant="outlined"
            sx={{ width: "100%" }}
            error={Boolean(errors.name)}
            helperText={errors.name}
          />
      </div>
      <div className='col-md-6 mt-3'>
      <TextField
           label="Email"
           value={Data.email}
           name='email'
           type='email'
           onChange={(e) => handleInputChange('email', e.target.value)}
            id="outlined-basic"
            variant="outlined"
            sx={{ width: "100%" }}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
      </div>
      
    </div>
    <div className='row mx-0'>
      <div className='col-md-6 mt-3'>
      <TextField
           label="Phone Number"
           type='number'
           value={Data.phoneNumber}
           name='phoneNumber'
           onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
            id="outlined-basic"
            variant="outlined"
            sx={{ width: "100%" }}
            error={Boolean(errors.phoneNumber)}
            helperText={errors.phoneNumber}
          />
      </div>
      <div className='col-md-6 mt-3'>
      <TextField
           label="City"
           value={Data.city}
           name='city'
           onChange={(e) => handleInputChange('city', e.target.value)}
            id="outlined-basic"
            variant="outlined"
            sx={{ width: "100%" }}
            error={Boolean(errors.city)}
            helperText={errors.city}
          />
      </div>
      
    </div>
    <div className='row mt-3 mx-0'>
      <div className='col-md-12'>
      <TextField
  id="outlined-multiline-flexible"
  label="Message"
  value={Data.message}
  name='message'
  onChange={(e) => handleInputChange('message', e.target.value)}
  multiline
  maxRows={6}
  style={{ width: "100%" }}
  error={Boolean(errors.message)}
  helperText={errors.message}
/>
      </div>
     
      
    </div>
    <div className='row my-4 mx-0'>
      <div className='col-md-12' style={{textAlign:"left"}}>
  <button className='btn btn-lg googleSans-medium' 
  style={{backgroundColor:"#19154E", color:"white",fontSize:'18px',lineHeight:'18px', padding:'20px 20px'}}
  disabled={isLoading}
   onClick={submit}>{`${isLoading ? 'Sending...' : 'Submit Message'}`}</button>
</div>
</div>
</div>         
            </div>

        </div>
        <div className='col-md-6 text-end' style={{justifyContent:'end'}}>
            <div className='contact-banner'>
            <h3 className='head-contatt px-5 pt-4 pb-0'>Zetta Circles</h3>
            <p className='img-text ps-5'>
            Leverage agile frameworks to provide a robust <br />
            synopsis for strategy foster collaborative <br />
             thinking to further the overall value.
            </p>
            </div>
            </div>
      </div>
      {/* </div> */}
   
</>
  )
}

export default ContactUs