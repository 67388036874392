import React, { useEffect, useRef } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import img1 from "../Login/images/img1.png";
import img2 from "../Login/images/img2.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/slice/auth";
import MainButton from "../Buttons/MainButton";
import toast from "react-hot-toast";

const styles = {
  height: "720px",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
// ==========validation functionality start===========

const ResetPassword = () => {
  const params = useParams();
  const token = params.id;
  const navigate = useNavigate();
  const password = useRef({});
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const passwordValidation = "^(?=.*d)[0-9]{8,}$";
  password.current = watch("password", "");
  // ==========validation functionality end===========
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);
  const onSubmit = (data) => {
    dispatch(resetPassword({ token: token, data: data })).then(() => {
      navigate("/login");
      toast.success("!Password Change Successfully");
    });
  };

  useEffect(() => {
    let login = localStorage.getItem("access-token");
    if (login) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      <MDBContainer className="">
        <MDBRow className="g-0">
          <MDBCol md="6">
            <MDBCardImage
              src={img2}
              alt="login form"
              className="rounded-start w-100"
              style={styles}
            />
          </MDBCol>

          <MDBCol md="5" className="mx-auto">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <div className="">
                <div className="d-flex flex-row mt-2 align-items-end">
                  <img
                    src={img1}
                    alt=""
                    style={{ height: 90, width: 73, marginLeft: "20px" }}
                  />
                  <span
                    className=" logo-font   ml-5"
                    style={{
                      color: "#43425D",
                      fontSize: "48px",
                      fontWeight: "400",
                    }}
                  >
                    Zetta Circles
                  </span>
                </div>

                <p
                  className="fw-normal my-4  text-center montserrat-font"
                  style={{
                    letterSpacing: "1px",
                    lineHeight: "22px",
                    fontSize: "18px",
                    lineHeight: "47px",
                    letterSpacing: "-0.5px",
                  }}
                >
                  Please complete to create your account.
                </p>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ padding: "0 30px" }}
                >
                  <Grid container>
                    <TextField
                      id="standard-password-input1"
                      label="Password"
                      type="password"
                      autoComplete="current-password"
                      variant="standard"
                      fullWidth
                      style={{ margin: "3px 0" }}
                      {...register("password", {
                        required: "You must specify a password",
                        pattern: {
                          value: passwordValidation,
                          message:
                            "Password should contain at least one digit, one lower case, one upper case and 8 characters",
                        },
                      })}
                    />
                    {errors.password && (
                      <div className="text-danger mt-1">
                        {errors.password.message}
                      </div>
                    )}
                  </Grid>
                  {/* ==============row 5================= */}
                  <Grid container>
                    <TextField
                      id="standard-password-input2"
                      label="Confirm Password"
                      type="password"
                      autoComplete="current-password"
                      variant="standard"
                      fullWidth
                      style={{ marginTop: "5px", marginBottom: "20px" }}
                      {...register("passwordConfirm", {
                        required: "You must specify a password",

                        validate: (value) =>
                          value === password.current ||
                          "The passwords do not match",
                      })}
                    />
                    {errors.passwordConfirm && (
                      <div className="text-danger mb-3">
                        {errors.passwordConfirm.message}
                      </div>
                    )}
                  </Grid>
                  {/* ============================================ */}

                  <div className="mb-4 ">
                    <MainButton
                      type={"submit"}
                      label={isLoading ? "Loading..." : "Reset"}
                    />
                  </div>
                </form>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default ResetPassword;
