import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Switch, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import image from "../../Images/Vector.png";
import { useDispatch, useSelector } from "react-redux";
import { AllUserAndOrganization, blockUser } from "../../redux/slice/auth";
import DeleteUser from "./DeleteUser";
import OrganizationUsers from "./OrganizationUsers";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as Userlisticon } from "../../assets/icons/userList.svg";
import { ReactComponent as Deleteicon } from "../../assets/icons/delete.svg";
import toast from "react-hot-toast";

const OrganizationsScreen = () => {
  const [allUser, setAllUser] = useState([]);
  const [docId, setDocId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust as needed

  const dispatch = useDispatch();

  const getUpdatedValues = useSelector(
    (state) => state.user.userData.UserAndOrganizationList
  );
  const { isLoading } = useSelector((state) => state.user);

  const totalUsers = allUser.length;
  const totalPages = Math.ceil(totalUsers / itemsPerPage);

  // Toggle Modals
  const toggleUserListModal = () => {
    setCreateUserModalOpen(!createUserModalOpen);
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  useEffect(() => {
    const getData = async () => {
      await dispatch(AllUserAndOrganization()).then((item) => {
        if (item.payload.data.length !== 0) {
          setAllUser([...item.payload.data].reverse());
        } else {
          setAllUser([]);
        }
      });
    };
    getData();
  }, [dispatch]);

  // Handle Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredUsers = allUser
    .filter((user) =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const toggleUserStatus = async (User) => {
    const userIndex = allUser.findIndex((item) => item._id === User._id);
    const updatedUsers = [...allUser];

    const isActive = User.status === "active";
    const newStatus = isActive ? "false" : "active";

    updatedUsers[userIndex] = { ...User, status: newStatus };
    setAllUser(updatedUsers);
    const res = await dispatch(
      blockUser({ status: newStatus, userId: User._id })
    );
    if (res.payload.message) {
      toast.success("Status Updated Successfully!");
    }
  };

  // Update totalPages when allUser or searchQuery changes
  useEffect(() => {
    setCurrentPage(1); // Reset to first page on data change
  }, [allUser, searchQuery]);

  return (
    <>
      <div className="px-md-5">
        <div className="my-4">
          <h3
            className={`dark-text`}
            style={{ marginBottom: "80px", fontWeight: "600" }}
          >
            Organization List
          </h3>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="d-flex align-items-center search-include">
              <SearchIcon className="mx-2 search-icon-color" />
              <input
                className="folder-search"
                type="search"
                placeholder="Search Item..."
                aria-label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table mt-4">
            <thead>
              <tr>
                <th className={`ps-3 fw-bold fs-6 `} scope="col">
                  Name
                </th>
                <th className={` fw-bold fs-6 `} scope="col">
                  Status
                </th>
                <th className=" fw-bold fs-6" scope="col">
                  Phone.No
                </th>
                <th className=" fw-bold fs-6" scope="col">
                  Email
                </th>
                <th className={` fw-bold fs-6 `} scope="col">
                  Change Status
                </th>
                <th className={` fw-bold fs-6 `} scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="6" className="p-4 text-center">
                    Loading...
                  </td>
                </tr>
              ) : filteredUsers.length === 0 ? (
                <tr>
                  <td colSpan="6" className="p-4 text-center">
                    No Data
                  </td>
                </tr>
              ) : (
                filteredUsers.map((data, i) => (
                  <tr
                    key={data._id}
                    style={{
                      backgroundColor: `white`,
                      border: `10px solid #F8F8F8`,
                    }}
                  >
                    <th scope="row">
                      <div className="d-flex">
                        <img src={image} alt="" />
                        <p className="ms-2 pt-2">{data.name}</p>
                      </div>
                    </th>
                    <td className="pt-3">
                      <Button
                        variant="outlined"
                        sx={{
                          backgroundColor: `${
                            data.status === "active"
                              ? "#2EBD92"
                              : data.status === "false"
                              ? "#dd4747"
                              : "#bcbc1b"
                          }`,
                          color: "black !important",
                          width: "120px",
                          letterSpacing: "1.3px",
                          textTransform: "uppercase",
                          fontSize: "12px",
                          fontWeight: "700",
                          borderRadius: "8px",
                        }}
                        disabled
                      >
                        {data.status === "pending"
                          ? "Invited"
                          : data.status === "active"
                          ? "Active"
                          : "Deactive"}
                      </Button>
                    </td>

                    <td className="pt-3">
                      <span>{data.phNo ? data.phNo.slice(0, 15) : "NIL"}</span>
                    </td>
                    <td className="pt-3">
                      <span>{data.email ? data.email : "NIL"}</span>
                    </td>
                    <td className="pt-2">
                      <Switch
                        disabled={data.status === "pending"}
                        sx={{
                          "& .MuiSwitch-colorPrimary": {
                            color: "#28C76F",
                          },
                        }}
                        checked={
                          data.status === "active" && data.status !== "false"
                        }
                        onChange={() => toggleUserStatus(data)}
                      />
                    </td>

                    <td>
                      <div className="icon-container py-2">
                        <div className="icon">
                          <Userlisticon
                            onClick={() => {
                              toggleUserListModal();
                              setDocId(data);
                            }}
                            style={{
                              cursor: "pointer",
                              fill: `black`,
                              stroke: `black`,
                            }}
                          />
                        </div>
                        <div className="vertical-line"></div>
                        <div className="icon">
                          <Deleteicon
                            onClick={() => {
                              toggleDeleteModal();
                              setDocId(data);
                            }}
                            style={{ cursor: "pointer", fill: `black` }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {/* Pagination Controls */}
          {filteredUsers.length > 0 && (
            <div className="d-flex justify-content-between align-items-center my-3">
              <div>
                Page {currentPage} of {totalPages}
              </div>
              <div>
                <Button
                  variant="contained"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  sx={{ marginRight: 2 }}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </div>
            </div>
          )}

          {/* Modals */}
          <DeleteUser
            ID={docId}
            closeModal={toggleDeleteModal}
            ModalIsOpen={deleteModalOpen}
          />
          <OrganizationUsers
            org_id={docId}
            closeModal={toggleUserListModal}
            ModalIsOpen={createUserModalOpen}
          />
        </div>
      </div>
    </>
  );
};

export default OrganizationsScreen;
