import { Grid } from '@mui/material'
import React from 'react'
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import feature1 from '../../Images/feature-icon/feature1.png'
import feature2 from '../../Images/feature-icon/feature2.png'

import feature3 from '../../Images/feature-icon/feature3.png'
import feature4 from '../../Images/feature-icon/feature4.png'
import feature5 from '../../Images/feature-icon/feature5.png'
import feature6 from '../../Images/feature-icon/feature6.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const  SecondPortion = () => {

 const featureList =[
        {
            logo:feature1,
            title:"Easy",
            description:`Set up our document management 
            software in minutes & enjoy `
        },
        {
            logo:feature2,
            title:"Cost-effective",
            description:`Subscription-based and priced flexibly 
            according to your needs.`
        },
        {
            logo:feature3,
            title:"Accurate",
            description:`100% reliable document change 
            control & version control software.`
        },
        {
            logo:feature4,
            title:"Cloud-based",
            description:`Provide access to a single, consistent 
            document repository – anywhere, any time.`
        },
        {
            logo:feature5,
            title:"Automated",
            description:`Automated document approval workflows, 
            task notifications, & document archiving.`
        },
        {
            logo:feature6,
            title:"Secure",
            description:`Daily backups, encryption, fine-grained 
            access control, & full document ...`
        }
       
    ]


    
  return (
<>
<div className="" style={{backgroundColor:'#F5F9FD'}}>
<div className='pb-5 container' >
    <div className="d-flex w-100 text-center pt-5">
    <div className='w-100'>
          <p className='googleSans-bold' style={{color:"#F90401"}}>Features</p>
          
          <p className='  googleSans-bold' style={{color:'#19154E', fontSize:'50px'}}>The Most User-Friendly Document <br/>
Management System</p>
    </div>
  

</div>

<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
  {featureList?.map((item, index) => (
    <Grid item xs={4} sm={4} md={4} key={index}>
      
        <div className="card border-0 mx-2">
            <div className="card-body text-center">
                   <div className="d-flex w-100 justify-content-center">
                  <img className='my-4' src={item.logo} style={{width:'50px'}} alt="" />
            </div>
           
        <p className=' googleSans-bold fs-5' style={{color:'#19154E'}} > {item.title}</p>
        <p className='text-muted googleSans-medium'>{item.description}</p>
        <p className=' googleSans-bold ' style={{color:'#19154E'}} > Get Start <ArrowForwardIcon/> </p>
        </div>
            </div>
         
       
       
    </Grid>
  ))}
</Grid>
</div>

</div>


</>
    )
}

export default SecondPortion