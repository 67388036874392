import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slice/auth"
import organizationReducer from "./slice/organization"
import documentSlice from "./slice/DocumentsCrudSlice";
import ContactSlice from "./slice/ContactSlice";
const store = configureStore({
    reducer: {
        user: authReducer,
        organization: organizationReducer,
        document:documentSlice,
        contact:ContactSlice
    }
})

export default store;