import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSharedDocument } from "../../redux/slice/DocumentsCrudSlice";
import { useEffect } from "react";
import { useTheme } from "../../ThemeProvider";
import TablePagination from "../pagination/Pagination";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditorComponent from "../textEditer/EditorComponent";
import ViewDocModal from "../Documents/FilesEnvirment/ViewDocModal";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as Viewicon } from "../../assets/icons/view.svg";
import { ReactComponent as Pencilicon } from "../../assets/icons/pencil.svg";
import IMG from "../../../src/Images/Extensions/xlsx.png";
import IMG1 from "../../../src/Images/Extensions/docx.png";
import IMG2 from "../../../src/Images/Extensions/pdf.jpg";
import IMG3 from "../../../src/Images/Extensions/txt.png";
import IMG4 from "../../../src/Images/Extensions/image.png";
import IMG5 from "../../../src/Images/Extensions/powerpoint.png";
import IMG6 from "../../../src/Images/Extensions/doc-svgrepo-com.png";
import noExtension from "../../../src/Images/Extensions/noExtension.png";

export default function FilesListTable() {
  const { theme } = useTheme();
  const [viewFile, setViewfile] = useState(false);
  const [getfileData, setgetfileData] = useState();
  const [fileName, setfileName] = useState("");

  const [getfileDataForEdit, setgetfileDataForEdit] = useState();

  const [replaceModal, setreplaceModal] = useState(false);

  const { isLoading } = useSelector((state) => state.document);

  const [Data, setData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  function viewFileModalScreen(item) {
    setgetfileData(item?.documentId);
    setViewfile(!viewFile);
  }

  function ReplaceModalScreen(item) {
    setgetfileDataForEdit(item);
    setreplaceModal(!replaceModal);
    setfileName(item?.documentId?.name);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    const getSharedDocuments = async () => {
      const res = await dispatch(getSharedDocument());
      setData(res?.payload?.data);
    };
    getSharedDocuments();
  }, [dispatch]);

  const paginatedlist = (val) => {
    setFilteredData(val);
  };

  useEffect(() => {
    if (!isLoading) {
      setFilteredData(Data);
    }
  }, [isLoading]);

  useEffect(() => {
    const filtered = Data.filter(
      (item) =>
        item?.documentId?.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item?.documentId?.description
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        item.permission.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchTerm]);

  const extensionToImageMap = {
    ".xlsx": IMG,
    ".xls": IMG,
    ".docx": IMG1,
    ".pptx": IMG5,
    ".pdf": IMG2,
    ".txt": IMG3,
    ".jpg": IMG4,
    ".png": IMG4,
    ".jpeg": IMG4,
    ".doc": IMG6,
  };
  const getExtensionImage = (filename) => {
    if (!filename) return null;
    const ext = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    return extensionToImageMap["." + ext] || null;
  };
  return (
    <>
      <div className="px-md-5">
        <div className=" my-4">
          <h3
            className={`bdms-title table-text`}
            style={{ marginBottom: "80px" }}
          >
            Collaboration
          </h3>
        </div>
        <div className="row" style={{ marginLeft: "2px" }}>
          <div className="col-md-3">
            <div className="d-flex align-items-center search-include">
              <SearchIcon className="mx-2 search-icon-color" />
              <input
                className=" folder-search"
                type="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={`Search Item...`}
                aria-label="Search"
              />
            </div>
          </div>
        </div>
        <div className="table-responsive mt-5">
          <table
            className=""
            style={{
              width: "100%",
            }}
          >
            <thead className="px-3 table">
              <tr>
                <th
                  scope="col"
                  className={`ps-4 fw-bold table-text`}
                  style={{ width: "25%" }}
                >
                  Type{" "}
                </th>
                <th
                  scope="col"
                  className={`ps-4 fw-bold table-text`}
                  style={{ width: "25%" }}
                >
                  Name{" "}
                </th>
                <th
                  scope="col"
                  className={`fw-bold table-text`}
                  style={{ width: "25%" }}
                >
                  Modified
                </th>
                <th
                  scope="col"
                  className={`fw-bold table-text`}
                  style={{ width: "25%" }}
                >
                  Permission
                </th>
                <th
                  scope="col"
                  className={`fw-bold table-text`}
                  style={{ width: "25%" }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="px-3">
              {isLoading ? (
                <tr>
                  <td colSpan="6" className="">
                    <p style={{}} className={`fw-semibold fs-6 table-text`}>
                      Loading ...
                    </p>
                  </td>
                </tr>
              ) : !filteredData || filteredData?.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <p className="fs-6 mt-3">No File</p>
                  </td>
                </tr>
              ) : (
                filteredData?.map((item, i) => {
                  const docName = item?.documentId?.name || "";
                  const docDescription = item?.documentId?.description || "";
                  const permission = item?.permission || "";
                  const extensionImage = getExtensionImage(docName);

                  return (
                    <tr
                      className="table-row-border table-row-background"
                      key={i}
                    >
                      <td className="ps-3">
                        {extensionImage && (
                          <img
                            src={extensionImage}
                            alt="file type"
                            style={{ width: 50, height: 50 }}
                          />
                        )}
                      </td>
                      <td className="">
                        <div className="">
                          <p
                            className={`table-text`}
                            style={{
                              marginTop: 10,
                              margim: 10,
                              fontFamily: "GoogleSans,Sans-serif",
                            }}
                          >
                            {docName.length > 30
                              ? `${docName.slice(0, 30)}...`
                              : docName}
                            <small
                              className="text-muted"
                              style={{ fontSize: 10 }}
                            ></small>
                          </p>
                        </div>
                      </td>
                      <td className="">
                        <div className="">
                          <p
                            className={`table-text`}
                            style={{ marginTop: 10, margim: 10 }}
                          >
                            {docDescription}
                            <small
                              className="text-muted"
                              style={{ fontSize: 10 }}
                            ></small>
                          </p>
                        </div>
                      </td>
                      <td className="">
                        <div className="">
                          <p
                            className={`table-text`}
                            style={{ marginTop: 10, margim: 10 }}
                          >
                            {permission}
                            <small
                              className="text-muted"
                              style={{ fontSize: 10 }}
                            ></small>
                          </p>
                        </div>
                      </td>
                      <td className="">
                        <div className="my-3 d-flex justify-content-start align-items-center">
                          <Viewicon
                            style={{
                              cursor: "pointer",
                              marginTop: 5,
                              fill: `${theme === "light" ? "black" : "white"}`,
                            }}
                            onClick={() => viewFileModalScreen(item)}
                          />
                          {item.permission === "Viewer" ? (
                            ""
                          ) : (
                            <>
                              <div className="icon-container"></div>
                              <div className="icon">
                                <Pencilicon
                                  className={`table-text`}
                                  style={{
                                    cursor: "pointer",
                                    marginTop: 5,
                                    fill: `${
                                      theme === "light" ? "black" : "white"
                                    }`,
                                  }}
                                  onClick={() => ReplaceModalScreen(item)}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div className="ms-2">
            {Data?.length > 0 && (
              <TablePagination list={Data} paginatedList={paginatedlist} />
            )}
          </div>
        </div>
      </div>

      <EditorComponent
        fileData={getfileDataForEdit}
        // folderId={folderId}
        closeEditor={ReplaceModalScreen}
        EditorIsOpen={replaceModal}
        fileName={fileName}
        data="collab"
      />

      <ViewDocModal
        fileData={getfileData}
        closeModal={viewFileModalScreen}
        ModalIsOpen={viewFile}
      />
    </>
  );
}
