(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("docx"));
	else if(typeof define === 'function' && define.amd)
		define(["docx"], factory);
	else if(typeof exports === 'object')
		exports["QuillToWord"] = factory(require("docx"));
	else
		root["QuillToWord"] = factory(root["docx"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__687__) {
return 