import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import Modal from "react-modal";
import { FormContentWrapper } from "../Organizations/organization.style";
import "./user.css";
import { baseURL } from "../../axios/axios";
import { useTheme } from "../../ThemeProvider";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const EditUserModal = ({
  modalIsOpen,
  closeModal,
  refetch,
  handleUpdateUser,
  userId,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { theme } = useTheme();
  const [loading, setLoading] = useState(false);
  const [specificUserData, setSpecificUserData] = useState({});
  // Get Specific User Data
  useEffect(() => {
    if (userId) {
      axios
        .get(`${baseURL}api/user/organization/${userId}`, {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${JSON.parse(
              localStorage.getItem("access-token")
            )}`,
          },
        })
        .then((res) => {
          setSpecificUserData(res.data);
        });
    }
  }, [userId]);

  const onSubmit = (data) => {
    setLoading(true);
    fetch(`${baseURL}api/user/organization/${userId}`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${JSON.parse(
          localStorage.getItem("access-token")
        )}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        refetch();
        setLoading(false);
        toast.success("Successfully updated the user");
        closeModal();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <>
      <div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Box sx={{ padding: "40px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <IconButton onClick={closeModal} aria-label="delete">
                <CloseIcon
                  sx={{ color: `${theme === "light" ? "#000" : "white"}` }}
                />
              </IconButton>
            </Box>
            <Box>
              <Typography
                variant="h2"
                style={{ fontFamily: `GoogleSans, sans-serif` }}
                sx={{
                  fontSize: "30px",
                  fontWeight: "600",
                  color: `${theme === "light" ? "#000" : "white"}`,
                }}
              >
                Updated User
              </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <FormContentWrapper>
                <Box sx={{ width: "400px" }}>
                  <label
                    className="input-label"
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                  >
                    Email
                  </label>
                  <input
                    // placeholder="Please Enter Email"
                    value={specificUserData.email}
                    type="email"
                    className="input"
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                  />
                </Box>
                <Box sx={{ width: "400px" }}>
                  <label
                    className="input-label"
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                  >
                    Name
                  </label>
                  <input
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                    placeholder={specificUserData?.name}
                    type="text"
                    className="input"
                    {...register("name")}
                  />
                </Box>
              </FormContentWrapper>
              <FormContentWrapper>
                <Box sx={{ width: "400px" }}>
                  <label
                    className="input-label"
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                  >
                    Password
                  </label>
                  <input
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                    placeholder="Please Enter Password"
                    type="password"
                    className="input"
                    {...register("password")}
                    //   {...register("password", {
                    //     required: "Password is required.",
                    //   })}
                  />
                  {/* {errors.password && (
                  <div className="text-danger mb-2">
                    {errors.password.message}
                  </div>
                )} */}
                </Box>

                <Box sx={{ width: "400px" }}>
                  <label
                    className="input-label"
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                  >
                    Phone
                  </label>
                  <input
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                    placeholder="Please Enter Phone"
                    type="number"
                    className="input"
                    {...register("phNo")}
                    //   {...register("phNo", {
                    //     required: "Phone is required.",
                    //   })}
                  />
                  {/* {errors.phNo && (
                  <div className="text-danger mb-2">{errors.phNo.message}</div>
                )} */}
                </Box>
              </FormContentWrapper>

              <FormContentWrapper>
                <Box sx={{ width: "400px" }}>
                  <label
                    className="input-label"
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                  >
                    Country
                  </label>
                  <input
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                    placeholder="Please Enter Country"
                    type="text"
                    className="input"
                    {...register("country")}
                    //   {...register("country", {
                    //     required: "Country is required.",
                    //   })}
                  />
                  {/* {errors.country && (
                  <div className="text-danger mb-2">
                    {errors.country.message}
                  </div>
                )} */}
                </Box>
                <Box sx={{ width: "400px" }}>
                  <label
                    className="input-label"
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                  >
                    Industry
                  </label>
                  <input
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                    placeholder="Please Enter Industry"
                    type="text"
                    className="input"
                    {...register("industry")}
                    //   {...register("industry", {
                    //     required: "Industry is required.",
                    //   })}
                  />
                  {/* {errors.industry && (
                  <div className="text-danger mb-2">
                    {errors.industry.message}
                  </div>
                )} */}
                </Box>
              </FormContentWrapper>

              <FormContentWrapper>
                <Box sx={{ width: "400px" }}>
                  <label
                    className="input-label"
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                  >
                    Address
                  </label>
                  <input
                    style={{ fontFamily: `GoogleSans, sans-serif` }}
                    placeholder="Please Enter Address"
                    type="text"
                    className="input"
                    {...register("address")}
                    //   {...register("address", {
                    //     required: "Address is required.",
                    //   })}
                  />
                  {/* {errors.address && (
                  <div className="text-danger mb-2">
                    {errors.address.message}
                  </div>
                )} */}
                </Box>
              </FormContentWrapper>

              <Box sx={{ mt: "30px", textAlign: "center" }}>
                <button
                  type="submit"
                  style={{ fontFamily: `GoogleSans, sans-serif` }}
                  // onClick={handleUpdateUser}
                  className={`btn  btn-sm m-2  ${
                    theme === "light" ? "btn-dark" : "btn-primary"
                  }`}
                >
                  {loading ? "Loading..." : "Updated"}
                </button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default EditUserModal;
