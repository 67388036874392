import React, { useEffect, useState } from "react";

import FolderListTable from "./FolderListTable";
import FolderAddButton from "./FolderAddButton";
import FolderGridView from "./FolderGridView";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import Add from "@mui/icons-material/Add";
import NewFolderAdd from "./NewFolderAdd";
import "./folder.css";
import { baseURL } from "../../axios/axios";
export default function SubscriptionScreen({ onButtonClick, setFolderId }) {
  const [dataFromChild, setDataFromChild] = useState("");
  const [subscriptionData, setSubscriptionData] = useState([]);
  const updatedData = useSelector((state) => state.user.userData.userDetail);
  const [searchTitle, setsearchTitle] = useState();
  const [addFolderModalOpen, setaddFolderModalOpen] = useState(false);

  const setSearchTitle = (title) => {
    setsearchTitle(title);
  };

  // Callback function to receive data from the child
  const receiveDataFromChild = (id) => {
    const data = id;
    setDataFromChild(data);

    setFolderId(data);
  };

  const [check, setCheck] = useState("");

  function addFolderModalScreen() {
    setaddFolderModalOpen(!addFolderModalOpen);
  }

  useEffect(() => {
    fetchDataWithToken();
  }, []);

  const fetchDataWithToken = async () => {
    const token = JSON.parse(localStorage.getItem("access-token"));
    const url = `${baseURL}api/stripe/subscriptions`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();

      setSubscriptionData([...data.data]);
      return data;
    } catch (error) {
      console.error("Fetch error:", error);
      throw error;
    }
  };

  return (
    <>
      <div style={{ marginBottom: "80px", marginTop: "20px" }}>
        <h3 className="px-5 bdms-title">Subscription Details</h3>
      </div>

      <div className="row mx-5 my-5">
        <FolderAddButton setSearchTitle={setSearchTitle} />
      </div>
      {/* {updatedData?.data?.grid_view === "grid" ? (
        <FolderGridView
          searchTitle={searchTitle}
          sendDocIdToParent={receiveDataFromChild}
          filesScreen={onButtonClick}
        />
      ) : ( */}
      <FolderListTable
        searchTitle={searchTitle}
        sendDocIdToParent={receiveDataFromChild}
        filesScreen={onButtonClick}
        subscriptionData={subscriptionData}
      />
      {/* )} */}

      {/* <div
        className="d-flex justify-content-center align-items-center mx-5 add-folder-background"
        onClick={addFolderModalScreen}
        style={{ cursor: "pointer" }}
      >
        <div className="d-flex my-4">
          <div
            className="addicon d-flex justify-content-center align-items-center"
            style={{}}
          >
            <AddIcon className="add-folder-icon" style={{ fontSize: "50px" }} />
          </div>
          <div className="mx-2 d-flex align-items-center">
            <div>
              <p className="m-0 table-text" style={{ fontWeight: "700" }}>
                Add New Folder
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <NewFolderAdd
        closeModal={addFolderModalScreen}
        ModalIsOpen={addFolderModalOpen}
      /> */}
    </>
  );
}
