import { Box } from "@mui/material";
import React, { useContext } from "react";
import { TextField } from "@mui/material";
import { useTheme } from '../../ThemeProvider';

import {
  BackButton,
  ButtonWrapper,
  FormTitle,
  NextButton,
} from "./document.style";
import { MULTI_STEP_CONTEXT } from "../Context/MultiStepFormContext";
import DashButton from "../Buttons/DashButton";

const FormTabThree = ({ handleNext, handleBack, classes, activeStep }) => {
  const { title, description, setTitle, setDescription } =
    useContext(MULTI_STEP_CONTEXT);
    const { theme} = useTheme();
  return (
    <div>

      <Box component="form">
        <Box>
          <label className="label">Title</label>
          <input
            // value={title}
            onChange={(e) => setTitle(e.target.value)}
         
            id="outlined-basic"
           className="bdms-profile-input"
            sx={{ width: "100%" }}
          />
        </Box>

        <Box>
          <label className="label">Description</label>

          <textarea
            name="description"
            onChange={(e) => setDescription(e.target.value)}
            
            id=""
            rows={4}
            required
            className=" bdms-profile-input "
          ></textarea>
        </Box>

        <ButtonWrapper>
       
          {/* <button type="button"
          
          className={`btn    m-2  ${theme==='light'? 'btn-outline-dark':'btn-outline-light'}`}>
    back  
  </button> */}
  <DashButton
   disabled={activeStep === 0}
            onClick={handleBack}
            label="Back"
            color={"white"}
  />


        
          <DashButton
           onClick={handleNext} 
           type="submit"
           label="Next"
              disabled={!(description && title)}
              color={'dark'}
          />
        </ButtonWrapper>
      </Box>
    </div>
  );
};

export default FormTabThree;
