import React, { createContext, useState } from "react";

export const MULTI_STEP_CONTEXT = createContext();

const MultiStepFormContext = ({ children }) => {
  const [file, setFile] = useState({});
  const [inputTag, setInputTag] = useState([]);
  const [dateAndTime, setDateAndTime] = useState({});
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");


  const contextValue = {
    setFile,
    setInputTag,
    setDateAndTime,
    setTitle,
    setDescription,
    file,
    inputTag,
    dateAndTime,
    title,
    description,
  };

  return (
    <MULTI_STEP_CONTEXT.Provider value={contextValue}>
      {children}
    </MULTI_STEP_CONTEXT.Provider>
  );
};

export default MultiStepFormContext;
