import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  AllUserAndOrganization,
  DeleteUserOROrganization,
  organizationUsers,
  organizationSubscriptions,
} from "../../redux/slice/auth";
import CreateUserModal from "./CreateUserModal";
import { ReactComponent as Deleteicon } from "../../assets/icons/delete.svg";
import { toast } from "react-hot-toast";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "900px",
    maxWidth: "1300px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#f8f8f8",
  },
};

const OrganizationUsers = ({ org_id, ModalIsOpen, closeModal }) => {
  const [list, setlist] = useState([]);
  const updatedList = useSelector(
    (state) => state.user.userData.UpdatedListOfUsersOfOrg
  );

  const dispatch = useDispatch();

  const getInitalValues = async () => {
    const response = await dispatch(organizationUsers({ id: org_id }));
    const values = response?.payload?.data;

    setlist(values);
  };

  useEffect(() => {
    getInitalValues();
  }, [org_id]);

  useEffect(() => {
    if (updatedList?.data?.users) {
      setlist(updatedList?.data?.users);
    }
  }, [updatedList?.data?.users]);

  const deleteDoc = (ID) => {
    dispatch(DeleteUserOROrganization(ID)).then(() => {
      dispatch(AllUserAndOrganization());
      dispatch(organizationUsers({ id: org_id })).then((res) => {});
      toast.success("user deleted successfully");
    });
  };

  return (
    <Box>
      <Modal
        isOpen={ModalIsOpen}
        onRequestClose={closeModal}
        style={{ overlay: { zIndex: 9999 }, content: customStyles.content }}
        contentLabel="Example Modal"
      >
        <Box sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "30px 0",
            }}
          >
            <div className="d-flex ">
              <p className="h4 fw-bold">Users</p>
            </div>
            <IconButton onClick={closeModal} aria-label="delete">
              <CloseIcon sx={{ color: "#000" }} />
            </IconButton>
          </Box>

          <div className="table-responsive">
            <table class="table ">
              <thead>
                <tr className=" ">
                  <th className="p-3">Name</th>
                  <th className="p-3">status</th>
                  <th className="p-3">Country</th>
                  <th className="p-3">Contact Info</th>
                  <th className="p-3">Action</th>
                </tr>
              </thead>
              <tbody>
                {list?.length === 0 || !list ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <p className="fw-bold mt-4">No user</p>
                    </td>
                  </tr>
                ) : (
                  list?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="table-row-border table-row-background"
                      >
                        <td className="p-3">{item.name}</td>
                        <td className="p-3">{item.status}</td>
                        <td className="p-3">{item.country}</td>

                        <td className="p-3">{item.email}</td>
                        <td className="p-3">
                          <Box sx={{ display: "flex" }}>
                            <Deleteicon
                              style={{ fill: "black", cursor: "pointer" }}
                              onClick={() => {
                                deleteDoc(item?._id);
                                closeModal();
                              }}
                            />
                          </Box>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default OrganizationUsers;
