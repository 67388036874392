import axios from "axios";
import toast from "react-hot-toast";

const baseURL = 'https://api.anshinbase.com/';

// const baseURL = 'https://api.anshinbase.com/';

let lastActivityTime = new Date();

// Create Axios instances
const instance = axios.create({
  baseURL,
  withCredentials: true,
});

const FormData = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/form-data",
  },
});

const axiosPrivate = axios.create({
  baseURL,
  withCredentials: true,
});

// Function to check session expiration
function checkSessionExpiration() {
  const currentUser = localStorage.getItem("access-token");
  const currentTime = new Date();

  if (currentUser) {
    const timeDifference = (currentTime - lastActivityTime) / (1000 * 60);
    if (timeDifference >= 20) {
      localStorage.clear();
      toast.error("please login again!");
      window.location.href = "/login";
    }
  }
}

// Function to reset last activity time
function resetLastActivityTime() {
  lastActivityTime = new Date();
}

// Axios interceptor to track the time of each API call and check session expiration
instance.interceptors.request.use(function (config) {
  resetLastActivityTime();
  return config;
});

FormData.interceptors.request.use(function (config) {
  resetLastActivityTime();
  return config;
});

axiosPrivate.interceptors.request.use(function (config) {
  resetLastActivityTime();
  return config;
});

// Periodically check session expiration every 1 minute
setInterval(() => {
  checkSessionExpiration();
}, 60000);

// Track user activity to reset last activity time
window.addEventListener("mousemove", resetLastActivityTime);
window.addEventListener("keypress", resetLastActivityTime);

export { instance, baseURL, FormData, axiosPrivate };
