import React from 'react';
import AppearenceLayout from './AppearenceLayout';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useTheme } from '../../ThemeProvider';
export default function AppearenceContent() {
  const { theme} = useTheme();
  return (
    <>
      <div className="row justify-content-center">
      <div className="col-md-11">
  <div className={` ${theme==='light'? '':'dark-background'}`}>

          <div className='heading ' style={{display: "flex", flexDirection: "row",
     justifyContent: "space-between"}}>
      <div>
    <h3 className={`bdms-title mt-4 ${theme==='light'? 'dark-text':'light-text'}`}>
        Theme
    </h3>
    <p className={`m-0 googleSans-normal ${theme==='light'? 'dark-text':'light-text'}`}>Select your dashboard theme</p>
    </div>
    <div>

    </div>
    </div>
    <hr className={`${theme==='light'? 'dark-text':'light-text'}`}/>
    <div className="container">
      <AppearenceLayout/>
    </div>
    
    <hr className={`${theme==='light'? 'dark-text':'light-text'}`}/>
{/* Accent Color Start Here */}
    {/* <div className='heading ' style={{display: "flex", flexDirection: "row",
     justifyContent: "space-between"}}>
      <div>
    <h4 className={` ${theme==='light'? 'dark-text':'light-text'}`}>
       Accents Color
    </h4>
    <p className={` ${theme==='light'? 'dark-text':'light-text'}`}>Select your Application accents color(Papaya)</p>
    </div>
    <div style={{display: "flex", flexDirection: "row",margin:10}}>
       <div className='color1' style={{width: 20, height: 20, borderRadius: "50%",
        backgroundColor: "lightgreen",margin:5}} ></div>
          <div className='color2' style={{width: 20, height: 20, borderRadius: "50%",
        backgroundColor: "Brown",margin:5}} ></div>
          <div className='color3' style={{width: 20, height: 20, borderRadius: "50%",
      backgroundColor: "orange",margin:5}} ></div>
          <div className='color4' style={{width: 20, height: 20, borderRadius: "50%",
        backgroundColor: "Navyblue",margin:5}} ></div>
    </div>
    </div> */}
    {/* Accent Color End Here */}
    {/* <hr className={`${theme==='light'? 'dark-text':'light-text'}`}/> */}
    {/* Transparent slider Start Here */}
    {/* <div className='heading ' style={{display: "flex", flexDirection: "row",
     justifyContent: "space-between"}}>
      <div>
    <h4 className={` ${theme==='light'? 'dark-text':'light-text'}`}>
        TransParent Slider
    </h4>
    <p className={` ${theme==='light'? 'dark-text':'light-text'}`}>Add Transparency layer to your sidebar</p>
    </div>
    <div>
    <div className="form-check form-switch">
  <input
    className="form-check-input"
    type="checkbox"
    role="switch"
    id="flexSwitchCheckChecked"
    defaultChecked=""
 
  />

</div>

    </div>
    </div> */}
{/* Transparents End Here */}
  {/* <hr className={`${theme==='light'? 'dark-text':'light-text'}`}/> */}
    
    {/* Reset Defults Start Here */}
{/* 
    <div className='heading ' style={{display: "flex", flexDirection: "row",
     justifyContent: "space-between"}}>
      <div>
    <h5 className={`${theme==='light'? 'dark-text':'light-text'}`}>
    {<SettingsOutlinedIcon />}  Reset to defaults
    </h5>
  
    </div>
    <div>
    <button type="button" className={`btn   btn-sm m-2  ${theme==='light'? 'btn-outline-dark':'btn-outline-light'}`}>
    Cancel  
  </button>
  <button type="button" className={`btn  btn-sm m-2  ${theme==='light'? 'btn-dark':'btn-primary'}`}>
    Save Prefrences
  </button>
    </div>
    </div> */}
    {/* Reset Default End  Here */}
    </div>

      </div>
    </div>
  

    </>
    
  )
}
