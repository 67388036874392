import React, {useRef, useState, useEffect } from 'react';
// import { License } from '@syncfusion/ej2-base';

import './xlsx.css';
import {
    RangeDirective,
    RangesDirective,
    SheetDirective,
    SheetsDirective,
    SpreadsheetComponent,
    ColumnsDirective,
    ColumnDirective
  } from '@syncfusion/ej2-react-spreadsheet';
  import * as XLSX from 'xlsx';
  import { baseURL } from '../../axios/axios';
  import {
    Box,
    IconButton,
  
  } from "@mui/material";
const XlsxFileEditor = () => {

    
  const SSObj = useRef(null);
//   useEffect(() => {
//     License.registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfVtdXGFWfFN0QXNcdV5xfldCcC0sT3RfQFljSn9QdkBjXH1edn1cQA==');
//   }, []);
  

  const onBtnClick = () => {
    SSObj.current.insertSheet(0, 2);
    SSObj.current.moveSheet(0, [1]);
    SSObj.current.delete();
    SSObj.current.duplicateSheet();
    SSObj.current.refresh();
    SSObj.current.startEdit();
    SSObj.current.endEdit();
    SSObj.current.closeEdit();
  };

  const onCreate = () => {
    // Use fetch to get data from the live URL
    fetch('https://res.cloudinary.com/dy0pphqqd/raw/upload/v1710228035/ExcelWorkbook_qfw6dq.xlsx')
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => {
        const binaryString = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
        const workbook = XLSX.read(binaryString, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        const sheetModel = [
          {
            index: 0,
            name: 'Order Details',
            ranges: [{ dataSource: data }],
            columns: [
              { width: 150 },
              { width: 110 },
              { width: 110 },
              { width: 85 },
              { width: 85 },
              { width: 85 }
            ]
          }
        ];
        SSObj.current.insertSheet(sheetModel);
      })
      .catch(error => {
      console.error('Error fetching live data:', error);
      });
  };

  useEffect(() => {
    onCreate(); // Load data when the component mounts
  }, []); // Empty dependency array means this effect runs only once on mount

  return (<>
    <button className="e-btn custom" onClick={onBtnClick}>
    Insert Sheet
  </button>
  <SpreadsheetComponent
    ref={(s) => (SSObj.current = s)}
    created={onCreate}
  >
    <SheetsDirective>
      <SheetDirective showHeaders={false} showGridLines={false}></SheetDirective>
      <SheetDirective ></SheetDirective>
    </SheetsDirective>
  </SpreadsheetComponent>
  </>
  
  )
}

export default XlsxFileEditor