import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
// import { baseURL } from "../../axios/axios";
import { useTheme } from "../../ThemeProvider";
import {
  GetUserDetail,
  UpdateUserDetail,
  UpdatedPassword,
} from "../../redux/slice/auth";
import { Country } from "country-state-city";
import DashButton from "../Buttons/DashButton";

export default function UserProfile() {
  const [userDetails, setUserDetails] = useState({});
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);
  const updatedData = useSelector((state) => state.user.userData.userDetail);
  const { theme } = useTheme();
  const countryOptions = Country.getAllCountries();

  // get user data
  const getdetailsFun = async () => {
    const res = await dispatch(GetUserDetail());
    const value = res.payload;
    setUserDetails(value.data);
  };
  useEffect(() => {
    getdetailsFun();
  }, []);
  useEffect(() => {
    if (updatedData?.data) setUserDetails(updatedData?.data);
  }, [updatedData?.data]);

  // Function to handle avatar click and trigger file input

  const handleCountryCodeChange = (event) => {
    const selectedCountry = event.target.value;

    setUserDetails((prev) => ({
      ...prev,
      country: selectedCountry,
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // handle signUp
  const onSubmit = async (data) => {
    const keysToUpdate = [
      "name",
      "email",
      "address",
      "phNo",
      "industry",
      "country",
    ];

    const formdata = new FormData();

    // Assign missing values from userDetails to data (input fields)
    for (const key of keysToUpdate) {
      if (!data[key]) {
        data[key] = userDetails[key];
      }
    }

    // Append other form fields to the form data
    for (const key in data) {
      formdata.append(key, data[key]);
    }

    dispatch(UpdateUserDetail(formdata));

    try {
      await dispatch(GetUserDetail());
      toast.success("Successfully updated data");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update data");
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-11">
          <div className=" my-4 ">
            <div className=" ">
              <div
                className="setting"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              ></div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="  form"
                method="POST"
              >
                <div className="row" style={{ padding: "0 12px" }}>
                  <div className="col-md-6 p-0 pe-2">
                    <div className="mb-3 mt-0">
                      <label
                        className="form-label"
                        style={{
                          color: `${theme === "light" ? "#8f8e8e" : "white"}`,
                        }}
                      >
                        User Name
                      </label>
                      <br />
                      <input
                        style={{
                          backgroundColor: `${
                            theme === "light" ? "" : "#0c3a56"
                          }`,
                          color: `${theme === "light" ? "" : "white"}`,
                        }}
                        className="bdms-profile-input"
                        defaultValue={userDetails?.name}
                        {...register("name")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 p-0 ps-2">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                        style={{
                          color: `${theme === "light" ? "#8f8e8e" : "white"}`,
                        }}
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        style={{
                          backgroundColor: `${
                            theme === "light" ? "" : "#0c3a56"
                          }`,
                          color: `${theme === "light" ? "" : "white"}`,
                        }}
                        className="bdms-profile-input"
                        id="exampleFormControlInput1"
                        defaultValue={userDetails?.email}
                        readOnly
                        {...register("email")}
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Address
                  </label>
                  <input
                    className="bdms-profile-input"
                    id="exampleFormControlInput1"
                    defaultValue={userDetails?.address}
                    {...register("address")}
                  />
                  {/* {errors.address && errors.address.type === "required" && (
                <div className="text-danger mt-2">Address is required</div>
              )} */}
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Contact Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    className="bdms-profile-input"
                    style={{
                      backgroundColor: `${theme === "light" ? "" : "#0c3a56"}`,
                      color: `${theme === "light" ? "" : "white"}`,
                    }}
                    name="phone"
                    placeholder="03xx-xxxxxxx"
                    // pattern="[0-9]{4}-[0-9]{7}"
                    required
                    maxLength="11"
                    defaultValue={userDetails?.phNo}
                    {...register("phNo")}
                  />
                </div>

                <div className=" mb-4">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                    style={{
                      color: `${theme === "light" ? "#8f8e8e" : "white"}`,
                    }}
                  >
                    Industry
                  </label>
                  <input
                    placeholder="Enter Industry Name"
                    type="text"
                    style={{
                      backgroundColor: `${theme === "light" ? "" : "#0c3a56"}`,
                      color: `${theme === "light" ? "" : "white"}`,
                    }}
                    className="bdms-profile-input"
                    id="exampleFormControlInput1"
                    defaultValue={userDetails?.industry}
                    {...register("industry")}
                  />
                </div>

                <div className=" mb-4 ">
                  <label
                    className="form-label"
                    style={{
                      color: `${theme === "light" ? "#8f8e8e" : "white"}`,
                    }}
                  >
                    Country
                  </label>

                  <select
                    style={{
                      backgroundColor: `${theme === "light" ? "" : "#0c3a56"}`,
                      color: `${theme === "light" ? "" : "white"}`,
                      width: "100%",
                      height: 50,
                      borderRadius: "5px",
                      border: "1px solid #C2C2C2",
                      padding: "0 10px",
                    }}
                    name="country"
                    className="country-select  "
                    value={userDetails?.country}
                    onChange={handleCountryCodeChange}
                  >
                    <option value="" disabled>
                      Select Country
                    </option>
                    {countryOptions.map((country) => (
                      <option key={country.isoCode} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>

                  {errors.city && (
                    <div>
                      <div className="text-danger mt-2">
                        Country name is required
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-auto">
                  <DashButton
                    label={isLoading ? "Loading..." : "Save"}
                    type={"submit"}
                    color="dark"
                    disabled={isLoading}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
