import { Box, Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import FormTabOne from "./FormTabOne";
import FormTabTwo from "./FormTabTwo";
import FormTabThree from "./FormTabThree";
import FormTabFour from "./FormTabFour";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormProvider } from "react-hook-form";
import { useTheme } from '../../ThemeProvider';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "50%",
    maxHeight: "90%",

    transform: "translate(-50%, -50%)",
  },
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Add Files", "Add People", "Add Title", "Review"];
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ShareModal = ({ modalIsOpen, closeModal,fileData }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

 
const { theme} = useTheme();
  const classes = useStyles();
  const methods = useForm({});
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = (data) => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const setinitalstate=()=>{
    setActiveStep(0);
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormTabOne
            handleNext={handleNext}
            handleBack={handleBack}
            fileData={fileData}
            classes={classes}
            activeStep={activeStep}
          />
        );

      case 1:
        return (
          <FormTabTwo
            handleNext={handleNext}
            handleBack={handleBack}
            classes={classes}
            activeStep={activeStep}
          />
        );
      case 2:
        return (
          <FormTabThree
            handleNext={handleNext}
            handleBack={handleBack}
            classes={classes}
            activeStep={activeStep}
          />
        );
      case 3:
        return (
          <FormTabFour
            handleNext={handleNext}
            handleBack={handleBack}
            fileData={fileData}
            closeModal={closeModal}
            classes={classes}
            setinitalstate={setinitalstate}
            activeStep={activeStep}
          />
        );
      default:
        return "unknown step";
    }
  }

  return (
    <Box sx={{ width: "800px" }}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Box sx={{ padding: "0px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <IconButton onClick={()=>{
              closeModal()
              setActiveStep(0)
            }} aria-label="delete">
            <CloseIcon className={ `${theme==='light'? 'dark-text':'light-text'}` }  />
            </IconButton>
          </Box>

          <div>
            <Stepper alternativeLabel activeStep={activeStep}  >
              {steps.map((step, index) => {
                const labelProps = {};
                const stepProps = {};

                return (
                  <Step {...stepProps} key={index} >
                    <StepLabel {...labelProps}>{step}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === steps.length ? (
              <Typography variant="h3" align="center">
                Thank You
              </Typography>
            ) : (
              <>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(handleNext)}>
                    {getStepContent(activeStep)}
                  </form>
                </FormProvider>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default ShareModal;
