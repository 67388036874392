import { Box } from "@mui/material";
import IMG from "../../Images/Extensions/xlsx.png";
import IMG1 from  "../../Images/Extensions/docx.png"
import IMG2 from '../../Images/Extensions/pdf.jpg'
import IMG3 from "../../Images/Extensions/txt.png";
import IMG4 from "../../Images/Extensions/image.png";
import noExtension from '../../Images/Extensions/noExtension.png'
import {  ButtonWrapper,} from "./document.style";
import DashButton from "../Buttons/DashButton";
import './stepper.css';

const FormTabOne = ({ handleNext, handleBack, classes, activeStep,fileData }) => {

 
  const parts = fileData?.name?.split('.');
                 const fileExtension = parts?.length > 1? '.' + parts?.pop():''
                 const extensionToImageMap = {
                  '.xlsx': IMG,
                  '.xls':IMG,
                  '.docx': IMG1,
                  '.pdf': IMG2,
                  '.txt': IMG3,
                  '.jpg':IMG4,
                  '.png':IMG4,
                  '.jpeg':IMG4
                };
              
                const imageSource = extensionToImageMap[fileExtension] || noExtension;



  
  return (
    <div>
      <Box>

        <Box component="form">
          <Box>
            <label className="label" style={{ marginBottom: "10px" }}>
              File / Folder
            </label>
            <Box>
             <div className="d-flex border rounded-3 p-3 modal-body-color">
             <div className="">
              <img src={imageSource} 
              style={{ width: "50px", height: "50px" }} alt="" />
            </div>
              <div className="d-flex align-items-center mx-3"><p className="m-0 fw-semibold table-text" > {fileData?.name}</p></div>
              <div className="mx-5 d-flex align-items-center"><p className="m-0 table-text" >{fileData?.description}</p></div>
             

             </div>

            
               

              <ButtonWrapper>

                <DashButton
            label={'Next'}
            type={'submit'}
            color='dark'
            onClick={handleNext}
            />
              </ButtonWrapper>

            
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default FormTabOne;
