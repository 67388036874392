import { Box, Typography } from "@mui/material";

import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import EditUserModal from "./EditUserModal";
import { baseURL } from "../../axios/axios";
import { useTheme } from "../../ThemeProvider";
import { useDispatch, useSelector } from "react-redux";
import { blockUser } from "../../redux/slice/auth";
import { GetOrgUserApi } from "../../redux/slice/organization";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton, Tooltip } from "@mui/material";
import { ReactComponent as Deleteicon } from "../../assets/icons/delete.svg";
import DeleteUserModal from "./DeleteUserModal";

const UsersTable = ({ allUsers }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const { theme } = useTheme();
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function DeleteModalScreen(id) {
    setUserId(id);
    setDeleteModalOpen(!DeleteModalOpen);
  }

  const actionUser = async (user) => {
    const newStatus = user.status === "active" ? "false" : "active";
    await dispatch(blockUser({ status: newStatus, userId: user._id })).then(
      (res) => {
        toast.success("User status change successfully");
        dispatch(GetOrgUserApi());
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <>
          <Typography
            variant="h5"
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              textAlign: "center",
              color: "#000",
              marginTop: "30px",
            }}
          >
            Loading....
          </Typography>
        </>
      ) : (
        <>
          {allUsers?.length ? (
            <div className="table-responsive mt-5">
              <table
                className=""
                style={{
                  width: "100%",
                }}
              >
                <thead className="px-3 table">
                  <tr>
                    <th
                      scope="col"
                      className={`ps-3 fw-bold table-text`}
                      style={{ width: "30%" }}
                    >
                      Users{" "}
                    </th>
                    <th
                      scope="col"
                      className={` fw-bold table-text`}
                      style={{ width: "16.67%" }}
                    >
                      Status{" "}
                    </th>
                    <th
                      scope="col"
                      style={{ width: "16.67%" }}
                      className={` fw-bold table-text`}
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className={` fw-bold table-text `}
                      style={{ width: "16.67%" }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="px-3">
                  {isLoading ? (
                    <tr>
                      <td colSpan="6" className="">
                        <p className={`fw-semibold fs-6 table-text `}>
                          Loading ...
                        </p>
                      </td>
                    </tr>
                  ) : !allUsers || allUsers?.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <p className={`fw-semibold fs-6 table-text`}>No File</p>
                      </td>
                    </tr>
                  ) : (
                    allUsers?.map((data, i) => {
                      return (
                        <>
                          <tr
                            className="table-row-border table-row-background"
                            key={i}
                          >
                            <td className="ps-2">
                              <div className="">
                                <p
                                  className={`table-text`}
                                  style={{
                                    marginTop: 10,
                                    margim: 10,
                                    fontFamily: "GoogleSans,Sans-serif",
                                  }}
                                >
                                  {data.name?.slice(0, 20)}
                                  <small
                                    className="text-muted"
                                    style={{ fontSize: 10 }}
                                  ></small>
                                </p>
                              </div>
                            </td>
                            <td className="">
                              <button
                                style={{ width: 90 }}
                                className={`btn  ${
                                  data.status === "info"
                                    ? "btn-secondary"
                                    : data.status === "active"
                                    ? "btn-success"
                                    : "btn-danger"
                                }`}
                                type="button"
                                disabled={data.status === "pending"}
                                onClick={() => actionUser(data)}
                              >
                                {data.status === "pending" ? (
                                  <span className="text-white fw-semibold py-2  rounded">
                                    {isLoading ? "Loading..." : "Invited"}
                                  </span>
                                ) : data.status === "active" ? (
                                  <span
                                    className="text-white fw-semibold py-2  rounded"
                                    style={{
                                      fontSize: "15px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {isLoading ? "Loading..." : "Active"}
                                  </span>
                                ) : (
                                  <span
                                    className="text-white fw-semibold py-2  rounded"
                                    style={{
                                      fontSize: "15px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {isLoading ? "Loading..." : "DeActive"}{" "}
                                  </span>
                                )}
                              </button>
                            </td>

                            <td className="">
                              <div className="table-text">
                                <p style={{ marginTop: 5, margim: 10 }}>
                                  {data.email}
                                </p>
                              </div>
                            </td>

                            <td>
                              <Tooltip title="Delete ">
                                <IconButton className="p-0 mb-3">
                                  <Deleteicon
                                    style={{
                                      fill: `${
                                        theme === "light" ? "black" : "white"
                                      }`,
                                    }}
                                    onClick={() => DeleteModalScreen(data.id)}
                                  />
                                </IconButton>
                              </Tooltip>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <Typography
              variant="h5"
              sx={{
                fontSize: "25px",
                fontWeight: "600",
                textAlign: "center",
                color: `${theme === "light" ? "#000" : "white"}`,
                marginTop: "30px",
              }}
            >
              No Data Available Here Please Create User
            </Typography>
          )}
        </>
      )}

      <>
        <EditUserModal
          closeModal={closeModal}
          modalIsOpen={modalIsOpen}
          userId={userId}
        />
        <DeleteUserModal
          closeModal={DeleteModalScreen}
          ModalIsOpen={DeleteModalOpen}
          userId={userId}
        />
      </>
    </>
  );
};

export default UsersTable;
