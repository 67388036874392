import { Box,  Stack } from '@mui/material';
import React, { useEffect, useState } from 'react'
import './pagination.css'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from 'react-bootstrap';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const TablePagination= ({list,paginatedList}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = list?.slice(indexOfFirstItem,indexOfLastItem);
  
    // Logic to handle page change
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
      
    };
  
    // Calculate the total number of pages
    const totalPages = Math.ceil(list?.length / itemsPerPage);

    useEffect(()=>{
        paginatedList(currentItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
 
  return (
   <>
      <Box sx={{
          display:"flex", 
          alignItems:"center", 
          justifyContent:"space-between",
          mt:"30px"
        }}>
        

          <Stack spacing={2}>
            {/* <Pagination  /> */}
      
            <Pagination  className='rounded-3'>
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {/* <FontAwesomeIcon icon={faAngleLeft}  /> */}
                  <ChevronLeftIcon className="p-2 " style={{fontSize:'40px'}}/>
                </Pagination.Prev>

                {Array.from({ length: totalPages }, (_, index) => (
                  <Pagination.Item
                    className="ps-3 "
                    style={{zIndex:'0'}}
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    <div className="p-2">{index + 1}</div>
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  className="ps-3"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  {/* <FontAwesomeIcon icon={faAngleRight}  /> */}
                  <ChevronRightIcon className="p-2" style={{fontSize:'40px'}}/>
                </Pagination.Next>
              </Pagination>
          </Stack>
        </Box>

   </>
  )
}

export default TablePagination