

import UserSendedApprovalList from './UserSendedApprovalList';
import WaitForMyApproval from './WaitForMyApproval';

import '../Profile/profile.css'

import React, { useState } from 'react';


const TabOfApproval = () => {
  const [activeTab, setActiveTab] = useState('MyApprovalRequest');

  const highlighthandle = (id) => {
    // Remove 'active-approval-tab' class from all tabs
    document.querySelectorAll('.tab-item').forEach((tab) => {
      tab?.classList?.remove('active-approval-tab');
    });

    // Add 'active-approval-tab' class to the clicked tab
    document.getElementById(id)?.classList?.add('active-approval-tab');

    // Update the activeTab state
    setActiveTab(id);
  };

  return (
    <>
    <div style={{marginBottom:'80px', marginTop:'20px'}}>
        <h3 className='px-5 bdms-title' >Approvals</h3>
    </div>
      <div className="tab-row">
        <div    className={`tab-item ${activeTab === 'MyApprovalRequest' ? 'active-approval-tab' : ''}`}
        id="MyapprovalRequest"
        onClick={() => highlighthandle('MyApprovalRequest')}>
             <p >
          My Approval Requests
        </p>
        </div>
        <div    className={`tab-item ${activeTab === 'WaitingMyApproval' ? 'active-approval-tab' : ''}`} 
        id="WaitingMyApproval"  
        onClick={() => highlighthandle('WaitingMyApproval')}>
              <p>
          Waiting For My Approval
        </p>
        </div>
       
      
      </div>
      <div className="show-screen">
        {activeTab==='MyApprovalRequest'?
        <UserSendedApprovalList/>
        :
        activeTab==='WaitingMyApproval' ?
    
     <WaitForMyApproval/> 
  :''
    }
      </div>
    </>
  );
};

export default TabOfApproval;























