import React from "react";
import "./style.css";
import { MDBCardBody, MDBCardImage } from "mdb-react-ui-kit";
import img1 from "../Login/images/img1.png";
import img2 from "../Login/images/img2.jpg";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "../../redux/slice/auth";
import MainButton from "../Buttons/MainButton";
import toast from "react-hot-toast";
import "./style.css";

const styles = {
  height: "800px",
};
const Reset = () => {
  const dispatch = useDispatch();
  const { isLoading, userData, error, message } = useSelector(
    (state) => state.user
  );

  // ==========validation functionality start===========
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmitData = (data) => {
    dispatch(forgetPassword(data)).then((res) => {
      if (res.error.message === "Rejected") {
        toast.error("Email not exist.");
      } else {
        toast.error("Email send!");
      }
    });
    // navigate("/");
  };

  // ==========validation functionality end===========
  return (
    <>
      <div className="row g-0">
        <div className="col-md-6 order-md-1 order-sm-2 d-none d-sm-block">
          <MDBCardImage
            src={img2}
            alt="login form"
            className="rounded-start w-100"
            style={styles}
          />
        </div>

        <div className="col-md-6 order-md-2 order-sm-2">
          <div className="d-flex justify-content-center align-items-center">
            <div className="">
              <MDBCardBody className="d-flex flex-column">
                <div className="d-flex flex-row mt-2 align-items-end custom-login">
                  <img
                    src={img1}
                    alt=""
                    style={{ height: 90, width: 73, marginLeft: "20px" }}
                  />
                  <span
                    className=" logo-font   ml-5"
                    style={{
                      color: "#43425D",
                      fontSize: "48px",
                      fontWeight: "400",
                    }}
                  >
                    Zetta Circles
                  </span>
                </div>

                <p
                  className="fw-normal my-4 pb-3 montserrat-font mx-md-0 mx-4"
                  style={{
                    fontSize: "18px",
                    lineHeight: "47px",
                    letterSpacing: "-0.5px",
                  }}
                >
                  Enter your email and we send you a password reset link.{" "}
                </p>
                <form
                  onSubmit={handleSubmit(onSubmitData)}
                  style={{ padding: "0 30px" }}
                >
                  <Grid container>
                    <TextField
                      id="standard-basic"
                      label="Email"
                      variant="standard"
                      fullWidth
                      style={{ margin: "1px 0" }}
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email.message}</div>
                    )}
                  </Grid>

                  <div className="mb-4 px-3 d-flex justify-content-center mt-5">
                    <MainButton
                      backgroundColor={"rgb(25, 21, 78)"}
                      type={"submit"}
                      label={isLoading ? "Loading..." : "Send Request"}
                    />
                  </div>
                </form>
              </MDBCardBody>
            </div>
          </div>

          {/* <div className=" text-center mt-5">
            <small className="text-center montserrat-font fs-6">
              Term of use{" "}
              <span>
                {" "}
                <Link style={{ textDecoration: "none" }} to="/login">
                  {" "}
                  Privacy Policy
                </Link>
              </span>{" "}
            </small>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Reset;
