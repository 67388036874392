import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllContactInfoApi } from "../../redux/slice/ContactSlice";
import ComplainModal from "./ComplainModal";
import TablePagination from "../pagination/Pagination"; // Assuming this is a custom component
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, Switch, Typography, Box } from "@mui/material";
import Pagination from "@mui/material/Pagination"; // Import MUI Pagination if preferred
import toast from "react-hot-toast";

export default function Complains() {
  const [allDataList, setAllDataList] = useState([]);
  const [replaceModal, setReplaceModal] = useState(false);
  const [queryData, setQueryData] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust as needed

  const dispatch = useDispatch();
  const { isLoading, complainData } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(GetAllContactInfoApi());
  }, [dispatch]);

  useEffect(() => {
    if (complainData && complainData.data) {
      setAllDataList(complainData.data);
    } else {
      setAllDataList([]);
    }
  }, [complainData]);

  // Calculate total pages
  const totalUsers = allDataList.length;
  const totalPages = Math.ceil(totalUsers / itemsPerPage);

  // Determine the data to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = allDataList.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Reset to first page when data changes
  useEffect(() => {
    setCurrentPage(1);
  }, [allDataList]);

  // Toggle Modal
  const toggleReplaceModal = (data) => {
    setQueryData(data);
    setReplaceModal(!replaceModal);
  };

  return (
    <>
      <h3 className="my-4 px-md-5" style={{ fontWeight: "600" }}>
        Complains
      </h3>
      <div className="my-1 p-md-5 m-0">
        <div className="row">
          <div className="table-responsive">
            <table className="table mt-4">
              <thead>
                <tr>
                  <th className="fw-bold fs-6" scope="col">
                    Name
                  </th>
                  <th className="fw-bold fs-6" scope="col">
                    Email
                  </th>
                  <th className="fw-bold fs-6" scope="col">
                    Phone No.
                  </th>
                  <th className="fw-bold fs-6" scope="col">
                    City
                  </th>
                  <th className="fw-bold fs-6" scope="col">
                    Message
                  </th>
                  <th className="fw-bold fs-6" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="6" className="p-4 text-center">
                      Loading...
                    </td>
                  </tr>
                ) : currentData.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="p-4 text-center">
                      No Data
                    </td>
                  </tr>
                ) : (
                  currentData.map((item) => {
                    const messageLength =
                      item.message.length > 20
                        ? item.message.slice(0, 20) + "..."
                        : item.message;
                    return (
                      <tr
                        key={item._id}
                        style={{
                          backgroundColor: `white`,
                          border: `10px solid #F8F8F8`,
                        }}
                      >
                        <td>
                          <p style={{ textTransform: "capitalize" }}>
                            {item.name}
                          </p>
                        </td>
                        <td>
                          <p>{item.email}</p>
                        </td>
                        <td>
                          <p>{item.phoneNumber}</p>
                        </td>
                        <td>
                          <p>{item.city}</p>
                        </td>
                        <td>
                          <p>{messageLength}</p>
                        </td>
                        <td>
                          <InfoOutlinedIcon
                            onClick={() => toggleReplaceModal(item)}
                            style={{ color: "black", cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination Controls */}
        {allDataList.length > itemsPerPage && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={3}
          >
            <Typography>
              Page {currentPage} of {totalPages}
            </Typography>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        )}

        {/* If you prefer custom buttons instead of MUI Pagination */}
        {/* 
        {allDataList.length > itemsPerPage && (
          <div className="d-flex justify-content-between align-items-center my-3">
            <div>
              Page {currentPage} of {totalPages}
            </div>
            <div>
              <Button
                variant="contained"
                onClick={() => setCurrentPage((prev) => prev - 1)}
                disabled={currentPage === 1}
                sx={{ marginRight: 2 }}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                onClick={() => setCurrentPage((prev) => prev + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </div>
        )}
        */}

        {/* Modal */}
        <ComplainModal
          queryData={queryData}
          closeModal={toggleReplaceModal}
          ModalIsOpen={replaceModal}
        />
      </div>
    </>
  );
}
